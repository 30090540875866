import React from 'react';
import s from './footer.module.scss';


const Footer: React.FC = () => {
  return (
    <div className={s.footer}>
      <div className="container">
        <div className={s.footerInside}>
          <div className={s.left}>
            {/*<div className={s.social}>
              <SocialMedia />
            </div>*/}
          </div>
          <div className={s.center}>
            <div className={s.siteRight}>
              FOOTROLL AI Bot 2024 @ All right reserved.
            </div>
          </div>
          <div className={s.right} />
        </div>
      </div>
    </div>
  );
};

export default Footer;
