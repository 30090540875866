// import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// import axios from 'axios';
// import { DOMAIN, MatchesAllEntities } from '../../../api.common';
// import { getTodayDate } from '../../../../app/helper/get-date';


// const initialState = {
//   matches: [],
//   loading: false,
//   error: '',
// }

// export const fetchMatchesGoldToday = createAsyncThunk('matches/fetchMatchesGoldToday', () => {
//   return axios
//     .get(`${DOMAIN}/all_events_gold_cards/${getTodayDate()}`, {
//       headers: {
//         'Authorization': `Bearer =TxcSDFCXVRGVESFSFRgrs34fsdfsd`
//       }
//     })
//     .then((response) => response.data)
// })

// export const matchesGoldTodaySlice = createSlice({
//   name: 'matchesGoldToday',
//   initialState,
//   reducers: {},
//   extraReducers: (builder: ActionReducerMapBuilder<any>) => {
//     builder.addCase(fetchMatchesGoldToday.pending, (state: MatchesAllEntities) => {
//       state.loading = true;
//     });
//     builder.addCase(fetchMatchesGoldToday.fulfilled, (state: MatchesAllEntities, action: any) => {
//       state.loading = false;
//       state.matches = action.payload;
//       state.error = '';
//     });
//     builder.addCase(fetchMatchesGoldToday.rejected, (state: MatchesAllEntities, action: any) => {
//       state.loading = false;
//       state.matches = [];
//       state.error = action.error.message;
//     });
//   },
// });

// import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import axios from 'axios';
// import { DOMAIN } from '../../../api.common';

// // Определим тип данных, которые мы будем хранить в selectedData
// interface SelectedData {
//   singleOnePlus28: number | null;
//   singleOnePlus48: number | null;
//   singleOnePlus72: number | null;
// }

// interface MatchesAllEntities {
//   matches: any[];
//   loading: boolean;
//   error: string;
//   selectedData: SelectedData; // Добавили новое поле selectedData
// }

// const initialState: MatchesAllEntities = {
//   matches: [],
//   loading: false,
//   error: '',
//   selectedData: {
//     singleOnePlus28: null,
//     singleOnePlus48: null,
//     singleOnePlus72: null,
//   },
// };

// export const fetchMatchesGoldToday = createAsyncThunk('matches/fetchMatchesGoldToday', () => {
//   return axios
//     .get(`${DOMAIN}/prediction_hot_events_for_telegram/all_numbers`, {
//       headers: {
//         'Authorization': `Bearer =TxcSDFCXVRGVESFSFRgrs34fsdfsd`
//       }
//     })
//     .then((response) => response.data)
// });

// export const matchesGoldTodaySlice = createSlice({
//   name: 'matchesGoldToday',
//   initialState,
//   reducers: {},
//   extraReducers: (builder: ActionReducerMapBuilder<any>) => {
//     builder.addCase(fetchMatchesGoldToday.pending, (state: MatchesAllEntities) => {
//       state.loading = true;
//     });
//     builder.addCase(fetchMatchesGoldToday.fulfilled, (state: MatchesAllEntities, action: any) => {
//       state.loading = false;

//       // Обработка полученного ответа
//       const data = action.payload;

//       // Сохраняем только нужные данные
//       state.selectedData = {
//         singleOnePlus28: data.id_all_in_one_plus_28 || null,  
//         singleOnePlus48: data.id_all_in_one_plus_48 || null,
//         singleOnePlus72: data.id_all_in_one_plus_72 || null,
//       };

//       state.error = '';
//     });
//     builder.addCase(fetchMatchesGoldToday.rejected, (state: MatchesAllEntities, action: any) => {
//       state.loading = false;
//       state.matches = [];
//       state.error = action.error.message;
//     });
//   },
// });

// export default matchesGoldTodaySlice.reducer;
import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { DOMAIN } from '../../../api.common';

// Определим тип данных, которые мы будем хранить в selectedData
interface SelectedData {
  singleOnePlus28: number | null;
  singleOnePlus48: number | null;
  singleOnePlus72: number | null;
}

interface MatchesAllEntities {
  matches: any[];
  loading: boolean;
  error: string;
  selectedData: SelectedData; // Добавили новое поле selectedData
}

const initialState: MatchesAllEntities = {
  matches: [],
  loading: false,
  error: '',
  selectedData: {
    singleOnePlus28: null,
    singleOnePlus48: null,
    singleOnePlus72: null,
  },
};

export const fetchMatchesGoldToday = createAsyncThunk('matches/fetchMatchesGoldToday', () => {
  return axios
    .get(`${DOMAIN}/prediction_hot_events_for_telegram/all_numbers`, {
      headers: {
        'Authorization': `Bearer =TxcSDFCXVRGVESFSFRgrs34fsdfsd`
      }
    })
    .then((response) => response.data)
});

export const matchesGoldTodaySlice = createSlice({
  name: 'matchesGoldToday',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<any>) => {
    builder.addCase(fetchMatchesGoldToday.pending, (state: MatchesAllEntities) => {
      state.loading = true;
    });
    builder.addCase(fetchMatchesGoldToday.fulfilled, (state: MatchesAllEntities, action: any) => {
      state.loading = false;

      // Обработка полученного ответа
      const data = action.payload;

      // Сохраняем только нужные данные
      state.selectedData = {
        singleOnePlus28: data.id_all_in_one_plus_28 || null,  
        singleOnePlus48: data.id_all_in_one_plus_48 || null,
        singleOnePlus72: data.id_all_in_one_plus_72 || null,
      };

      state.error = '';
    });
    builder.addCase(fetchMatchesGoldToday.rejected, (state: MatchesAllEntities, action: any) => {
      state.loading = false;
      state.matches = [];
      state.error = action.error.message;
    });
  },
});

export default matchesGoldTodaySlice.reducer;
