import React from 'react';
import s from './layout.module.scss';
import { Outlet } from 'react-router-dom';
import Footer from '../elements/footer/footer';
import { CustomerBasicModal } from '../../store/slices/customerSlice';
import { useAppSelector } from '../helper/redux-hook';
import ModalGetBonus from '../modals/modal-get-bonus';


const Layout: React.FC = () => {
  const modalStatus = useAppSelector(state => state.customerSlice.openedBasicModal);

  return (
    <>
      <main className={s.main}>
        <Outlet />
      </main>

      <Footer/>

      {modalStatus === CustomerBasicModal.getBonus && (
        <ModalGetBonus />
      )}
    </>
  );
};
export default Layout;
