import React, { SyntheticEvent } from 'react';
import s from './button.module.scss';
import { cn } from '../../helper/classNames';


export type ButtonTypes =
  /* color */
  'default' |
  'secondary' |
  'main' |
  'blur' |
  /* size */
  'big';

const modifiersMap = {
  /* color */
  'default' : s.default,
  'secondary' : s.secondary,
  'main' : s.main,
  'blur' : s.blur,
  /* size */
  'big' : s.big,
}

export type ButtonIconPosition = 'left' | 'right';

const iconPositionsMap = {
  'left' : s.left,
  'right' : s.right,
}

interface ButtonProps {
  text: string;
  modifiers: ButtonTypes[];
  type?: 'button' | 'submit' | 'reset';
  icon?: string;
  iconPosition?: ButtonIconPosition;
  clickHandler?: (e?: SyntheticEvent) => void;
}

const Button: React.FC<ButtonProps> = (
  {
    text,
    modifiers,
    type = 'button',
    icon,
    iconPosition = 'right',
    clickHandler,
  }
) => {
  const buttonModifiersSet = modifiers.map((buttonType) => modifiersMap[buttonType]);

  const fullClassName = cn([
    s.button,
    ...buttonModifiersSet,
  ]);

  return (
    <button
      type={type}
      onClick={clickHandler}
      className={fullClassName}
    >
      {text}

      {!!icon && (
        <div
          className={cn([
            s.icon,
            iconPositionsMap[iconPosition],
          ])}
        >
          <img
            src={icon}
            alt="iconButton"
          />
        </div>
      )}
    </button>
  );
};

export default Button;
