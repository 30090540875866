import React from 'react';
import type { FC } from 'react';
import s from './leagues.module.scss';
import { cn } from '../../helper/classNames';
import { getIsMobile } from '../../common';
import { DraggedScroll } from '../../elements/dragger/dragger-scroll';


interface League {
  logo_path: string;
  count_events: number;
}

interface CountryLeagues {
  [leagueName: string]: League;
}

export interface LeaguesCountryData {
  [countryName: string]: CountryLeagues;
}

interface LeaguesDataProps {
  data: LeaguesCountryData;
  selectedLeague: any;
  setSelectedLeague: (league: any) => void;
}

const Leagues: FC<LeaguesDataProps> = (
  {
    data,
    selectedLeague,
    setSelectedLeague,
  }
) => {
  const isMobile = getIsMobile();

  return (
    <div
      className={cn([
        s.leagueContainerWrapper,
        isMobile && s.isMobile,
      ])}
    >
      <button
        className={cn([
          s.league,
          s.all,
          !selectedLeague && s.active
        ])}
        onClick={() => setSelectedLeague(null)}
      >
        <div className={s.leagueName}>
          {'All'}
        </div>
      </button>
      <div
        className={cn([
          s.leagueContainer,
          isMobile && s.isMobile,
        ])}
      >
        <DraggedScroll>
          {Object.keys(data).map(country => (
            <div
              key={country}
              className={s.leagueWrapper}
            >
              <div className={s.leagueTitle}>
                {country}:
              </div>
              {Object.keys(data[country]).map(league => (
                <button
                  key={league}
                  className={cn([
                    s.league,
                    (selectedLeague?.country === country && selectedLeague?.league === league) && s.active,
                  ])}
                  onClick={() => setSelectedLeague({ country, league })}
                >
                  <img
                    className={s.leagueIcon}
                    src={data[country][league].logo_path}
                    alt={`${league} Logo`}
                  />
                  <div className={s.leagueName}>
                    {league}
                  </div>
                  <div className={s.leagueCount}>
                    {data[country][league].count_events}
                  </div>
                </button>
              ))}
            </div>
          ))}
        </DraggedScroll>
      </div>
    </div>
  )
};

export default Leagues;
