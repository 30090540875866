import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  today: {
    count: 15,
    title: 'Today'
  },
  yesterday: {
    count: 15,
    title: 'Yesterday'
  },
  tomorrow: {
    count: 15,
    title: 'Tomorrow'
  },
  after_tomorrow: {
    count: 15,
    title: 'Tomorrow'
  },
};

const moreCountSlice = createSlice({
  name: 'moreCount',
  initialState,
  reducers: {
    setMoreCountToday(state, action) {
      state.today.count += action.payload;
    },
    setMoreCountYesterday(state, action) {
      state.yesterday.count += action.payload;
    },
    setMoreCountTomorrow(state, action) {
      state.tomorrow.count += action.payload;
    },
    setMoreCountAfterTomorrow(state, action) {
      state.after_tomorrow.count += action.payload;
    },
  },
});

export const { setMoreCountToday, setMoreCountYesterday, setMoreCountTomorrow, setMoreCountAfterTomorrow } =
  moreCountSlice.actions;

export default moreCountSlice.reducer;
