import { FC, memo, PropsWithChildren } from 'react';
import s from './modal-portal.module.scss';
import { cn } from '../../helper/classNames';
import { useHandleOnOutsideClickHook } from '../../helper/handle-on-outside-click.hook';
import { useHandleOnEscapeHook } from '../../helper/handle-on-escape.hook';


export type UIPortalType = 'base' | 'notice';
export type UIPortalModifier = 'exclude-left-menu';

const typesMap = {
  base: s.baseType,
  notice: s.noticeType,
};

const modifiersMap = {
  'exclude-left-menu': s.excludeLeftMenu,
};

export interface ModalPortalProps {
  portalType?: UIPortalType;
  modifiers?: UIPortalModifier[];
  customClass?: string;
  title?: string;
  closeOnEscape?: boolean;
  closeOnOutsideClick?: boolean;
  onClose?: () => void;
}

const ModalPortalInner: FC<PropsWithChildren<ModalPortalProps>> = ({
  children,
  portalType = 'base',
  modifiers,
  customClass,
  title,
  closeOnEscape,
  closeOnOutsideClick,
  onClose,
}) => {
  const { containerRef } = useHandleOnOutsideClickHook({
    active: closeOnOutsideClick,
    onClick: onClose,
  });

  useHandleOnEscapeHook({
    active: closeOnEscape,
    onEscape: onClose,
  });

  return (
    <div
      className={cn([
        'ui-modal-portal',
        s.modal,
        typesMap[portalType],
        portalType,
        customClass,
        ...(modifiers || []).map((mod) => modifiersMap[mod])
      ])}
    >
      <div
        className={cn([
          'ui-modal-portal__content',
          s.content
        ])}
        ref={containerRef}
      >
        {title && (
          <div className={s.title}>
            {title}
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

const _ModalPortalInner = memo(ModalPortalInner);

export { _ModalPortalInner as ModalPortalInner };
