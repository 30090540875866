// import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// import axios from 'axios';
// import { DOMAIN, MatchesAllEntities } from '../../../api.common';
// import { getLastFridayOrCurrentDate } from '../../../../app/helper/get-date';


// const initialState = {
//   matches: [],
//   loading: false,
//   error: '',
// }

// export const fetchMatchesJackpot = createAsyncThunk('matches/fetchMatchesJackpot', () => {
//   return axios
//     .get(`${DOMAIN}/get_all_events_for_jackpot/${getLastFridayOrCurrentDate()}`, {
//       headers: {
//         'Authorization': `Bearer =TxcSDFCXVRGVESFSFRgrs34fsdfsd`
//       }
//     })
//     .then((response) => response.data)
// })

// export const matchesJackpotSlice = createSlice({
//   name: 'matchesJackpotToday',
//   initialState,
//   reducers: {},
//   extraReducers: (builder: ActionReducerMapBuilder<any>) => {
//     builder.addCase(fetchMatchesJackpot.pending, (state: MatchesAllEntities) => {
//       state.loading = true;
//     });
//     builder.addCase(fetchMatchesJackpot.fulfilled, (state: MatchesAllEntities, action: any) => {
//       state.loading = false;
//       state.matches = action.payload;
//       state.error = '';
//     });
//     builder.addCase(fetchMatchesJackpot.rejected, (state: MatchesAllEntities, action: any) => {
//       state.loading = false;
//       state.matches = [];
//       state.error = action.error.message;
//     });
//   },
// });

// export default matchesJackpotSlice.reducer

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import { DOMAIN } from '../../../api.common';

const initialState = {
  matches: [],
  loading: false,
  error: '' as string | undefined,  // изменяем тип на string | undefined
}

// Используем POST-запрос для получения данных
export const fetchMatchesJackpot = createAsyncThunk('matches/fetchMatchesJackpot', async () => {
  try {
    const response = await axios.post(
      `${DOMAIN}/prediction_events_all_in_one`,
      {
        type: 'single_all_in_one',
        result: true,
      },
      {
        headers: {
          'Authorization': `Bearer =TxcSDFCXVRGVESFSFRgrs34fsdfsd`,
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data.events; // Получаем данные в нужном формате
  } catch (error) {
    throw error; // В случае ошибки передаем ее
  }
})

export const matchesJackpotSlice = createSlice({
  name: 'matchesJackpotToday',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMatchesJackpot.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchMatchesJackpot.fulfilled, (state, action) => {
      state.loading = false;
      state.matches = action.payload; // action.payload будет содержать список матчей
      state.error = '';
    });
    builder.addCase(fetchMatchesJackpot.rejected, (state, action) => {
      state.loading = false;
      state.matches = [];
      state.error = action.error.message;
    });
  },
});

export default matchesJackpotSlice.reducer;
