import React from 'react';
import s from './modal-get-bonus.module.scss';
import { ModalPortal } from '../elements/modal';
import bonusIcon from '../../assets/bonus.png';
import Button from '../elements/button/button';
import { getBonus } from '../common';
import { useAppDispatch } from '../helper/redux-hook';
import { customerCloseBasicModal } from '../../store/slices/customerSlice';
const BcGame = require('../../assets/bcGame.png') as string;


const ModalGetBonus: React.FC = () => {
  const dispatch = useAppDispatch();

  const closeModalGetBonus = () => {
    dispatch(customerCloseBasicModal())
  };

  return (
    <ModalPortal
      closeOnEscape
      closeOnOutsideClick
      customClass={s.modalPortal}
      onClose={closeModalGetBonus}
    >
      <div className={s.modal}>
        <img
          className={s.image}
          src={BcGame}
          alt="BetFury"
        />
        <div className={s.title}>
          Get a $5 Free Bet!
        </div>
        <div className={s.description}>
        Sign up by our PROMO - FOOTROLL5 and get a personal bonus - $5 Free Bet Now.
        </div>
        <Button
          text="Get bonus"
          modifiers={['main']}
          icon={bonusIcon}
          clickHandler={getBonus}
        />
      </div>
    </ModalPortal>
  );
};

export default ModalGetBonus;
