import React from 'react';
import s from './title.module.scss';


interface TitleProps {
  title: string,
  count?: number,
}

const Title: React.FC<TitleProps> = (
  {
    title,
    count,
  }
) => {
  return (
    <div className={s.titleWrapper}>
      <h3 className={s.title}>
        {title}
      </h3>
      {count && (
        <div className={s.count}>
          {count}
        </div>
      )}
    </div>
  );
};

export default Title;
