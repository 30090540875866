// import type { FC } from 'react';
// import s from './match-text-block.module.scss';


// interface MatchTextBlockProps {
//   title?: string,
//   text: string,
// }

// const MatchTextBlock: FC<MatchTextBlockProps> = (
//   {
//     title,
//     text,
//   }
// ) => {
//   return (
//     <div className={s.content}>
//       {title && (
//         <b>
//           {title}
//           &nbsp;
//         </b>
//       )}
//       {text}
//     </div>
//   );
// };


// import React from 'react';

// interface MatchTextBlockProps {
//   title: string;
//   htmlContent: string;  // Свойство для HTML-контента
// }

// const MatchTextBlock: React.FC<MatchTextBlockProps> = ({ title, htmlContent }) => {
//   return (
//     <div>
//       <h3>{title}</h3>
//       <p dangerouslySetInnerHTML={{ __html: htmlContent }} />  {/* Рендерим HTML */}
//     </div>
//   );
// };

// export default MatchTextBlock;


// import React from 'react';

// interface MatchTextBlockProps {
//   title: string;
//   htmlContent: string;  // Свойство для HTML-контента
//   style?: React.CSSProperties;  // Стили для всего блока
//   titleStyle?: React.CSSProperties;  // Стили для заголовка
//   paragraphStyle?: React.CSSProperties;  // Стили для параграфа
// }

// const MatchTextBlock: React.FC<MatchTextBlockProps> = ({ title, htmlContent, style, titleStyle, paragraphStyle }) => {
//   return (
//     <div style={{ color: '#fff' }}>
//       <h3 style={titleStyle}>{title}</h3>
//       <p style={paragraphStyle} dangerouslySetInnerHTML={{ __html: htmlContent }} />  {/* Рендерим HTML */}
//     </div>
//   );
// };

// export default MatchTextBlock;

import React from 'react';

interface MatchTextBlockProps {
  title: string;
  htmlContent: string; 
}

const MatchTextBlock: React.FC<MatchTextBlockProps> = ({ title, htmlContent }) => {
  return (
    <div style={{ color: '#fff' }}>  {/* Белый текст */}
      <h3>{title}</h3>
      <pre style={{ whiteSpace: 'pre-wrap', fontSize: '16px' }} dangerouslySetInnerHTML={{ __html: htmlContent }} />  
    </div>
  );
};

export default MatchTextBlock;
