import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import {
  DOMAIN,
  ListOfChampionshipsEntities,
  ListOfChampionshipsProps,
} from '../../../api.common';


const initialState: ListOfChampionshipsProps = {
  list: [],
  loading: false,
  error: '',
}

export const fetchListOfChampionships = createAsyncThunk(
  'fetchListOfChampionships',
  async (date: string) => {
    try {
      const response = await axios.get(`${DOMAIN}/prediction_events_list/${date}`, {
        headers: {
          'Authorization': 'Bearer =TxcSDFCXVRGVESFSFRgrs34fsdfsd'
        }
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const listOfChampionshipsSlice = createSlice({
  name: 'listOfChampionships',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<any>) => {
    builder.addCase(fetchListOfChampionships.pending, (state: ListOfChampionshipsEntities) => {
      state.loading = true;
    });
    builder.addCase(fetchListOfChampionships.fulfilled, (state: ListOfChampionshipsEntities, action: any) => {
      state.list = action.payload;
      state.loading = false;
      state.error = '';
    });
    builder.addCase(fetchListOfChampionships.rejected, (state: ListOfChampionshipsEntities, action: any) => {
      state.list = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default listOfChampionshipsSlice.reducer
