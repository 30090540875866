// import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// import axios from 'axios';
// import { DOMAIN, MatchesAllEntities } from '../../../api.common';
// import { getTomorrowDate } from '../../../../app/helper/get-date';


// const initialState = {
//   matches: [],
//   loading: false,
//   error: '',
// }

// export const fetchMatchesHotTomorrow = createAsyncThunk('matches/fetchMatchesHotTomorrow', () => {
//   return axios
//     .get(`${DOMAIN}/prediction_hot_events/${getTomorrowDate()}`, {
//       headers: {
//         'Authorization': `Bearer =TxcSDFCXVRGVESFSFRgrs34fsdfsd`
//       }
//     })
//     .then((response) => response.data)
// });

// export const matchesHotTomorrowSlice = createSlice({
//   name: 'matchesHotTomorrow',
//   initialState,
//   reducers: {},
//   extraReducers: (builder: ActionReducerMapBuilder<any>) => {
//     builder.addCase(fetchMatchesHotTomorrow.pending, (state: MatchesAllEntities) => {
//       state.loading = true;
//     });
//     builder.addCase(fetchMatchesHotTomorrow.fulfilled, (state: MatchesAllEntities, action: any) => {
//       state.loading = false;
//       state.matches = action.payload;
//       state.error = '';
//     });
//     builder.addCase(fetchMatchesHotTomorrow.rejected, (state: MatchesAllEntities, action: any) => {
//       state.loading = false;
//       state.matches = [];
//       state.error = action.error.message;
//     });
//   },
// });

// export default matchesHotTomorrowSlice.reducer
import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { DOMAIN, MatchesAllEntities } from '../../../api.common';

const initialState = {
  matches: [],
  loading: false,
  error: '',
};

export const fetchMatchesHotTomorrow = createAsyncThunk('matches/fetchMatchesHotTomorrow', async () => {
  try {
    const response = await axios.post(`${DOMAIN}/prediction_events_all_in_one`,
      {
        type: 'single_all_in_one',
        closest: true,
      },
      {
        headers: {
          'Authorization': `Bearer =TxcSDFCXVRGVESFSFRgrs34fsdfsd`,
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const matchesHotTomorrowSlice = createSlice({
  name: 'matchesHotTomorrow',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<any>) => {
    builder.addCase(fetchMatchesHotTomorrow.pending, (state: MatchesAllEntities) => {
      state.loading = true;
    });
    builder.addCase(fetchMatchesHotTomorrow.fulfilled, (state: MatchesAllEntities, action: any) => {
      state.loading = false;
      state.matches = action.payload;
      state.error = '';
    });
    builder.addCase(fetchMatchesHotTomorrow.rejected, (state: MatchesAllEntities, action: any) => {
      state.loading = false;
      state.matches = [];
      state.error = action.error.message;
    });
  },
});

export default matchesHotTomorrowSlice.reducer;
