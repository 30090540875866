import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Layout from './layout/layout';
import Home from './pages/home';


const App = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="*" element={<p>There's nothing here: 404!</p>} />
      </Route>
    </Routes>
  );
};

export default App;
