// import React, { useCallback, useEffect, useMemo, useState } from 'react';
// import s from './gold-events.module.scss';
// import Title from '../../elements/title/title';
// import Tabs, { TabId } from '../../elements/tabs/tabs';
// import { useAppDispatch, useAppSelector } from '../../helper/redux-hook';
// import MatchItem, { matchType } from '../../elements/match-item/match-item';
// import { TabsMatchEntity } from '../../common';
// import MatchTextBlock from '../../elements/match-text-block/match-text-block';
// import Empty from '../../elements/empty/empty';
// import { cn } from '../../helper/classNames';
// import {
//   fetchMatchesGoldAfterTomorrow,
//   fetchMatchesGoldToday, fetchMatchesGoldTomorrow,
//   fetchMatchesGoldYesterday
// } from '../../../store/slices/matches';


// const title = 'Subscription';
// const GoldEventDescription = `on EXPERT365 is a Deep Learning model that analyzes team and player data to predict football results with precision.

// Integrated with Telegram, it provides real-time insights for 111 competitions worldwide, including the Champions League, Bundesliga, Premier League, La Liga, Serie A, and Ligue 1.

// We offer multiple payment options, including Stripe, PayPal, and Telegram Stars, making it easy for you to subscribe and unlock all predictions.

// Stay ahead of the game—subscribe today! 🚀`;

// const GoldEvents: React.FC = () => {
//   const dispatch = useAppDispatch();

//   const matchesTodayGold = useAppSelector(state => state.matchesGoldTodaySlice.matches);
//   const matchesYesterdayGold = useAppSelector(state => state.matchesGoldYesterdaySlice.matches);
//   const matchesTomorrowGold = useAppSelector(state => state.matchesGoldTomorrowSlice.matches);
//   const matchesAfterTomorrowGold = useAppSelector(state => state.matchesGoldAfterTomorrowSlice.matches);

//   const [currentTab, toggleTab] = useState<TabId>();

//   const tabs: TabsMatchEntity[] = useMemo(() => {
//     return [
//       {
//         'title': 'Yesterday',
//         'items':  matchesYesterdayGold,
//       },
//       {
//         'title': 'Today',
//         'items': matchesTodayGold,
//       },
//       {
//         'title': 'Tomorrow',
//         'items': matchesTomorrowGold,
//       },
//       {
//         'title': 'After tomorrow',
//         'items': matchesAfterTomorrowGold,
//       },
//     ];
//   }, [
//     matchesYesterdayGold,
//     matchesTodayGold,
//     matchesTomorrowGold,
//     matchesAfterTomorrowGold,
//   ]);

//   const tabListIsEmpty = useMemo(() => {
//     return tabs[0].items.length === 0
//       && tabs[1].items.length === 0
//       && tabs[2].items.length === 0
//       && tabs[3].items.length === 0;
//   },[tabs]);

//   const selectedDefaultTab = useCallback(() => {
//     // @ts-ignore
//     if (matchesTodayGold && !matchesTodayGold?.cards) {
//       return toggleTab(tabs[1].title);
//     }
//     // @ts-ignore
//     if (matchesTomorrowGold && !matchesTomorrowGold.cards) {
//       return toggleTab(tabs[2].title);
//     }
//     // @ts-ignore
//     if (matchesAfterTomorrowGold && !matchesAfterTomorrowGold.cards) {
//       return toggleTab(tabs[3].title);
//     }
//     // @ts-ignore
//     if (matchesYesterdayGold && !matchesYesterdayGold.cards) {
//       return toggleTab(tabs[0].title);
//     }
//   },[matchesYesterdayGold, matchesTodayGold, matchesTomorrowGold, matchesAfterTomorrowGold]);

//   useEffect(() => {
//     selectedDefaultTab();
//   }, [selectedDefaultTab]);

//   useEffect(() => {
//     dispatch(fetchMatchesGoldYesterday());
//     dispatch(fetchMatchesGoldToday());
//     dispatch(fetchMatchesGoldTomorrow());
//     dispatch(fetchMatchesGoldAfterTomorrow());
//   }, [dispatch]);

//   return (
//     <div className="container">
//       <div className={s.goldEvents}>
//         <div className={s.titleWrapper}>
//           <Title title={`🏆 ${title} events`} />
//           {!!tabs && (
//             <div className={s.tabs}>
//               <Tabs
//                 items={tabs}
//                 current={currentTab}
//                 onSelect={toggleTab}
//               />
//             </div>
//           )}
//         </div>

//         {tabs?.map(((tab: TabsMatchEntity) => currentTab === tab.title && !!tab.items.length && (
//           <div
//             key={tab.title}
//             className={cn([
//               'matches-items',
//               tab.items.length === 1 && 'matches-items--one-item',
//             ])}
//           >
//             {[...tab.items].reverse().map((match: any, index) => (
//               <div
//                 key={index}
//                 className="matches-item"
//               >
//                 <MatchItem
//                   match={match}
//                   matchType={matchType.gold}
//                 />
//               </div>
//             ))}
//           </div>
//         )))}

//         {tabListIsEmpty && (
//           <Empty />
//         )}

//         <MatchTextBlock
//           title={title}
//           text={GoldEventDescription}
//         />
//       </div>
//     </div>
//   );
// };

// export default GoldEvents;
// gold-events.tsx

// В файле gold-events.tsx

// WORK ALL IN GOLD EVENTS !!!!

// import React, { useState } from 'react';
// import s from './gold-events.module.scss';
// import Title from '../../elements/title/title';
// import Tabs, { TabId, Category } from '../../elements/tabs/tabs'; // Подключаем Category
// import MatchTextBlock from '../../elements/match-text-block/match-text-block';
// import Empty from '../../elements/empty/empty';
// import { cn } from '../../helper/classNames';

// const title = 'Subscription';
// const GoldEventDescription = `on EXPERT365 is a Deep Learning model that analyzes team and player data to predict football results with precision.

// Integrated with Telegram, it provides real-time insights for 111 competitions worldwide, including the Champions League, Bundesliga, Premier League, La Liga, Serie A, and Ligue 1.

// We offer multiple payment options, including Stripe, PayPal, and Telegram Stars, making it easy for you to subscribe and unlock all predictions.

// Stay ahead of the game—subscribe today! 🚀`;

// const GoldEvents: React.FC = () => {
//   const [currentTab, setCurrentTab] = useState<TabId>('Today');

//   // Таблица с данными
//   const tabs = [
//     { title: 'Today', items: [{ id_all_in_one_plus_24: 0 }] },
//     { title: 'Tomorrow', items: [{ id_all_in_one_plus_48: 0 }] },
//     { title: 'After tomorrow', items: [{ id_all_in_one_plus_72: 0 }] },
//   ];

//   // Фильтрация только нужных табов для Gold Events
//   const filteredTabs = tabs.filter(tab => ['Today', 'Tomorrow', 'After tomorrow'].includes(tab.title));

//   // category - нужное значение для пропа
//   const category: Category = 'GOLD EVENTS';  // Присваиваем нужное значение категории для Gold Events

//   return (
//     <div className="container">
//       <div className={s.goldEvents}>
//         <div className={s.titleWrapper}>
//           <Title title={`🏆 ${title} events`} />
  
//           {/* Отправляем только отфильтрованные табы и добавляем category */}
//           <div className={s.tabs}>
//             <Tabs
//               category={category}  // Передаем нужную категорию
//               items={filteredTabs}  // Передаем только нужные табы
//               current={currentTab}
//               onSelect={setCurrentTab}
//             />
//           </div>
//         </div>
  
//         {/* Проверяем, есть ли нужные табы */}
//         {filteredTabs.some(tab => tab.title === currentTab && tab.items.length > 0) ? (
//           filteredTabs
//             .filter(tab => tab.title === currentTab && tab.items.length > 0)
//             .map(tab => (
//               <div key={tab.title} className={cn(['matches-items', 'matches-items--one-item'])}>
//                 <div className="matches-item">
//                   <span>{tab.items.length}</span>
//                 </div>
//               </div>
//             ))
//         ) : (
//           <Empty />
//         )}
  
//         {/* Блок с описанием */}
//         <MatchTextBlock title={title} text={GoldEventDescription} />
//       </div>
//     </div>
//   );
// };

// export default GoldEvents;

// import React, { useState } from 'react';
// import s from './gold-events.module.scss';
// import Title from '../../elements/title/title';
// import Tabs, { TabId, Category } from '../../elements/tabs/tabs';
// import MatchTextBlock from '../../elements/match-text-block/match-text-block';  // Импортируем компонент
// import Empty from '../../elements/empty/empty';
// import { cn } from '../../helper/classNames';

// import paymentImage from '../../../assets/payment-image.png';

// const title = 'Subscription';

// // Пример описания с HTML-разметкой
// const allEventsDescription = `Subscription on EXPERT365 is a Deep Learning model that analyzes team and player data to predict football results with precision.
// <br />
// Integrated with Telegram, it provides real-time insights for 111 competitions worldwide, including the Champions League, Bundesliga, Premier League, La Liga, Serie A, and Ligue 1.
// <br />
// We offer multiple payment options, including Stripe, PayPal, and Telegram Stars, making it easy for you to subscribe and unlock all predictions.
// <br />
// Stay ahead of the game—subscribe today! 🚀<br /><br>`;

// const GoldEvents: React.FC = () => {
//   const [currentTab, setCurrentTab] = useState<TabId>('Today');

//   const tabs = [
//     { title: 'Today', items: [{ id_all_in_one_plus_24: 0 }] },
//     { title: 'Tomorrow', items: [{ id_all_in_one_plus_48: 0 }] },
//     { title: 'After tomorrow', items: [{ id_all_in_one_plus_72: 0 }] },
//   ];

//   const filteredTabs = tabs.filter(tab => ['Today', 'Tomorrow', 'After tomorrow'].includes(tab.title));
//   const category: Category = 'GOLD EVENTS';

//   return (
//     <div className="container">
//       <div className={s.goldEvents}>
//         <div className={s.titleWrapper}>
//           <Title title={`🏆 ${title} events`} />
//           <div className={s.tabs}>
//             <Tabs category={category} items={filteredTabs} current={currentTab} onSelect={setCurrentTab} />
//           </div>
//         </div>

//         {filteredTabs.some(tab => tab.title === currentTab && tab.items.length > 0) ? (
//           filteredTabs
//             .filter(tab => tab.title === currentTab && tab.items.length > 0)
//             .map(tab => (
//               <div key={tab.title} className={cn(['matches-items', 'matches-items--one-item'])}>
//                 <div className="matches-item">
//                   <span>{tab.items.length}</span>
//                 </div>
//               </div>
//             ))
//         ) : (
//           <Empty />
//         )}

//         <div className={s.paymentWrapper}>
//           <a href="https://t.me/Expert365ai_bot?start=67c4afc3abd4a7671f075af2" target="_blank" rel="noopener noreferrer">
//             <img 
//               src={paymentImage}
//               alt="Subscribe Now"
//               className={s.paymentImage}
//               style={{ 
//                 maxWidth: '370px', 
//                 maxHeight: '400px', 
//                 width: 'auto', 
//                 height: 'auto', 
//                 border: '2px solid #ccc',
//                 borderRadius: '15px' 
//               }}  
//             />
//           </a>
//           <p className={s.paymentDescription}>
//             Click on the image to subscribe to our service and unlock all predictions.
//           </p>
//         </div>

//         {/* Передаем htmlContent вместо text */}
//         <MatchTextBlock 
//           title={title} 
//           htmlContent={allEventsDescription} 
//         />
//       </div>
//     </div>
//   );
// };

// export default GoldEvents;

// import React, { useState } from 'react';
// import s from './gold-events.module.scss';
// import Title from '../../elements/title/title';
// import Tabs, { TabId, Category } from '../../elements/tabs/tabs';
// import MatchTextBlock from '../../elements/match-text-block/match-text-block';  // Импортируем компонент
// import Empty from '../../elements/empty/empty';
// import { cn } from '../../helper/classNames';

// import paymentImage from '../../../assets/payment-image.png';

// const title = 'Subscription';

// // Пример описания с HTML-разметкой
// const allEventsDescription = `Subscription on EXPERT365 is a Deep Learning model that analyzes team and player data to predict football results with precision.
// <br />
// Integrated with Telegram, it provides real-time insights for 111 competitions worldwide, including the Champions League, Bundesliga, Premier League, La Liga, Serie A, and Ligue 1.
// <br />
// We offer multiple payment options, including Stripe, PayPal, and Telegram Stars, making it easy for you to subscribe and unlock all predictions.
// <br />
// Stay ahead of the game—subscribe today! 🚀<br /><br>`;

// const GoldEvents: React.FC = () => {
//   const [currentTab, setCurrentTab] = useState<TabId>('Today');

//   const tabs = [
//     { title: 'Today', items: [{ id_all_in_one_plus_24: 0 }] },
//     { title: 'Tomorrow', items: [{ id_all_in_one_plus_48: 0 }] },
//     { title: 'After tomorrow', items: [{ id_all_in_one_plus_72: 0 }] },
//   ];

//   const filteredTabs = tabs.filter(tab => ['Today', 'Tomorrow', 'After tomorrow'].includes(tab.title));
//   const category: Category = 'GOLD EVENTS';

//   return (
//     <div className="container">
//       <div className={s.goldEvents}>
//         <div className={s.titleWrapper}>
//           <Title title={`🏆 ${title} events`} />
//           <div className={s.tabs}>
//             <Tabs category={category} items={filteredTabs} current={currentTab} onSelect={setCurrentTab} />
//           </div>
//         </div>

//         {filteredTabs.some(tab => tab.title === currentTab && tab.items.length > 0) ? (
//           filteredTabs
//             .filter(tab => tab.title === currentTab && tab.items.length > 0)
//             .map(tab => (
//               <div key={tab.title} className={cn(['matches-items', 'matches-items--one-item'])}>
//                 <div className="matches-item">
//                   <span>{tab.items.length}</span>
//                 </div>
//               </div>
//             ))
//         ) : (
//           <Empty />
//         )}

//         <div className={s.paymentWrapper}>
//           <a href="https://t.me/Expert365ai_bot?start=67c4afc3abd4a7671f075af2" target="_blank" rel="noopener noreferrer">
//             <img 
//               src={paymentImage}
//               alt="Subscribe Now"
//               className={s.paymentImage}
//               style={{ 
//                 maxWidth: '370px', 
//                 maxHeight: '400px', 
//                 width: 'auto', 
//                 height: 'auto', 
//                 border: '2px solid #ccc',
//                 borderRadius: '15px' 
//               }}  
//             />
//           </a>
//           <p className={s.paymentDescription}>
//             Click on the image to subscribe to our service and unlock all predictions.
//           </p>
//           <a 
//             href="https://t.me/Expert365ai_bot?start=67c4afc3abd4a7671f075af2" 
//             target="_blank" 
//             rel="noopener noreferrer" 
//             className={s.subscribeButton}
//           >
//             Subscribe Now
//           </a>
//         </div>


//         {/* Передаем htmlContent вместо text */}
//         <MatchTextBlock 
//           title={title} 
//           htmlContent={allEventsDescription} 
//         />
//       </div>
//     </div>
//   );
// };

// export default GoldEvents;


// Обнови код GoldEvents.tsx
// Добавляем useEffect для обработки источника трафика:

// Теперь кнопка и изображение будут вести на разные ссылки в зависимости от параметра src в URL.

// Примеры использования:

// https://expert365-production.up.railway.app/?src=stream1 → ссылка на https://t.me/Expert365ai_bot?start=67cf5c5b398cf0fc790b03c4
// https://expert365-production.up.railway.app/?src=stream2 → ссылка на https://t.me/Expert365ai_bot?start=67cf5cc6bd79f9c9a408ca57
// Любой другой случай → ссылка на https://t.me/Expert365ai_bot?start=67c4ab9d39bde08f7804462e

// import React, { useState } from 'react';
// import s from './gold-events.module.scss';
// import Title from '../../elements/title/title';
// import Tabs, { TabId, Category } from '../../elements/tabs/tabs';
// import MatchTextBlock from '../../elements/match-text-block/match-text-block';
// import Empty from '../../elements/empty/empty';
// import { cn } from '../../helper/classNames';
// import paymentImage from '../../../assets/payment-image.png';
// import SubscribeButton from '../../elements/subscribe/subscribe-button';

// const title = 'Subscription';

// const allEventsDescription = `Subscription on EXPERT365 is a Deep Learning model that analyzes team and player data to predict football results with precision.
// <br />
// Integrated with Telegram, it provides real-time insights for 111 competitions worldwide, including the Champions League, Bundesliga, Premier League, La Liga, Serie A, and Ligue 1.
// <br />
// We offer multiple payment options, including Stripe, PayPal, and Telegram Stars, making it easy for you to subscribe and unlock all predictions.
// <br />
// Stay ahead of the game—subscribe today! 🚀<br><br />`;

// const GoldEvents: React.FC = () => {
//   const [currentTab, setCurrentTab] = useState<TabId>('Today');

//   const tabs = [
//     { title: 'Today', items: [{ id_all_in_one_plus_24: 0 }] },
//     { title: 'Tomorrow', items: [{ id_all_in_one_plus_48: 0 }] },
//     { title: 'After tomorrow', items: [{ id_all_in_one_plus_72: 0 }] },
//   ];

//   const filteredTabs = tabs.filter(tab => ['Today', 'Tomorrow', 'After tomorrow'].includes(tab.title));
//   const category: Category = 'GOLD EVENTS';

//   return (
//     <div className="container">
//       <div className={s.goldEvents}>
//         <div className={s.titleWrapper}>
//           <Title title={`🏆 ${title} events`} />
//           <div className={s.tabs}>
//             <Tabs category={category} items={filteredTabs} current={currentTab} onSelect={setCurrentTab} />
//           </div>
//         </div>

//         {filteredTabs.some(tab => tab.title === currentTab && tab.items.length > 0) ? (
//           filteredTabs
//             .filter(tab => tab.title === currentTab && tab.items.length > 0)
//             .map(tab => (
//               <div key={tab.title} className={cn(['matches-items', 'matches-items--one-item'])}>
//                 <div className="matches-item">
//                   <span>{tab.items.length}</span>
//                 </div>
//               </div>
//             ))
//         ) : (
//           <Empty />
//         )}

//         <div className={s.paymentWrapper}>
//           <a href="https://t.me/Expert365ai_bot?start=subscription" target="_blank" rel="noopener noreferrer">
//             <img 
//               src={paymentImage}
//               alt="Subscribe Now"
//               className={s.paymentImage}
//               style={{ 
//                 maxWidth: '370px', 
//                 maxHeight: '400px', 
//                 width: 'auto', 
//                 height: 'auto', 
//                 border: '2px solid #ccc',
//                 borderRadius: '15px' 
//               }}  
//             />
//           </a>
//           <p className={s.paymentDescription}>
//             Click on the image to subscribe to our service and unlock all predictions.
//           </p>

//           {/* Используем компонент SubscribeButton */}
//           <div className={s.subscribeButtonWrapper}>
//             <SubscribeButton />
//           </div>
//         </div>

//         <MatchTextBlock 
//           title={title} 
//           htmlContent={allEventsDescription} 
//         />
//       </div>
//     </div>
//   );
// };

// export default GoldEvents;
import React, { useState } from 'react';
import s from './gold-events.module.scss';
import Title from '../../elements/title/title';
import Tabs, { TabId, Category } from '../../elements/tabs/tabs';
import MatchTextBlock from '../../elements/match-text-block/match-text-block';
import Empty from '../../elements/empty/empty';
import { cn } from '../../helper/classNames';
import SubscribeButton from '../../elements/subscribe/subscribe-button';

const title = 'Subscription';

const allEventsDescription = `Subscription on EXPERT365 is a Deep Learning model that analyzes team and player data to predict football results with precision.
<br />
Integrated with Telegram, it provides real-time insights for 111 competitions worldwide, including the Champions League, Bundesliga, Premier League, La Liga, Serie A, and Ligue 1.
<br />
We offer multiple payment options, including Stripe, PayPal, and Telegram Stars, making it easy for you to subscribe and unlock all predictions.
<br />
Stay ahead of the game—subscribe today! 🚀<br><br />`;

const GoldEvents: React.FC = () => {
  const [currentTab, setCurrentTab] = useState<TabId>('Today');

  // Здесь мы ПОЛУЧАЕМ РЕАЛЬНОЕ КОЛИЧЕСТВО матчей в каждом табе!
  const tabs = [
    { title: 'Today', count: 3 },      // 3 матча
    { title: 'Tomorrow', count: 3 },   // 3 матча
    { title: 'After tomorrow', count: 2 }, // 2 матча
  ];

  const category: Category = 'GOLD EVENTS';

  // Получаем данные о количестве матчей в текущем табе
  const currentTabData = tabs.find(tab => tab.title === currentTab);

  return (
    <div className="container">
      <div className={s.goldEvents}>
        <div className={s.titleWrapper}>
          <Title title={`🏆 ${title} events`} />
          <div className={s.tabs}>
            <Tabs 
              category={category} 
              items={tabs} 
              current={currentTab} 
              onSelect={setCurrentTab} 
            />
          </div>
        </div>

        {currentTabData && currentTabData.count > 0 ? (
          <div className={s.cardsWrapper}>
            {Array.from({ length: currentTabData.count }).map((_, index) => (
              <div key={index} className={s.eventCard}>
                <div className={s.eventCardBlur}>
                  <SubscribeButton />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <Empty />
        )}

        <MatchTextBlock 
          title={title} 
          htmlContent={allEventsDescription} 
        />
      </div>
    </div>
  );
};

export default GoldEvents;
