import { createSlice, PayloadAction } from '@reduxjs/toolkit';


export enum CustomerBasicModal {
  none = 'none',
  getBonus = 'getBonus',
}

export const initialCustomerState: CustomerState = {
  openedBasicModal: CustomerBasicModal.none,
}
export interface CustomerState {
  openedBasicModal: CustomerBasicModal;

}
const customerSlice = createSlice({
  name: 'customerSlice',
  initialState: initialCustomerState as CustomerState,
  reducers: {
    customerOpenBasicModal: (
      state,
      action: PayloadAction<CustomerBasicModal>,
    ) => {
      state.openedBasicModal = action.payload;
    },
    customerCloseBasicModal: (state) => {
      state.openedBasicModal = CustomerBasicModal.none;
    },
  },
});

export const { customerOpenBasicModal, customerCloseBasicModal } = customerSlice.actions;

export default customerSlice.reducer;
