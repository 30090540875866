// import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// import axios from 'axios';
// import { DOMAIN, MatchesAllEntities } from '../../../api.common';
// import { getTodayDate } from '../../../../app/helper/get-date';


// const initialState = {
//   matches: [],
//   loading: false,
//   error: '',
// }

// export const fetchMatchesHotToday = createAsyncThunk('matches/fetchMatchesHotToday', () => {
//   return axios
//     .get(`${DOMAIN}/prediction_hot_events/${getTodayDate()}`, {
//       headers: {
//         'Authorization': `Bearer =TxcSDFCXVRGVESFSFRgrs34fsdfsd`
//       }
//     })
//     .then((response) => response.data)
// })

// export const matchesHotTodaySlice = createSlice({
//   name: 'matchesHotToday',
//   initialState,
//   reducers: {},
//   extraReducers: (builder: ActionReducerMapBuilder<any>) => {
//     builder.addCase(fetchMatchesHotToday.pending, (state: MatchesAllEntities) => {
//       state.loading = true;
//     });
//     builder.addCase(fetchMatchesHotToday.fulfilled, (state: MatchesAllEntities, action: any) => {
//       state.loading = false;
//       state.matches = action.payload;
//       state.error = '';
//     });
//     builder.addCase(fetchMatchesHotToday.rejected, (state: MatchesAllEntities, action: any) => {
//       state.loading = false;
//       state.matches = [];
//       state.error = action.error.message;
//     });
//   },
// });

// export default matchesHotTodaySlice.reduce
import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { DOMAIN, MatchesAllEntities } from '../../../api.common';

const initialState = {
  matches: [],
  loading: false,
  error: '',
};

export const fetchMatchesHotToday = createAsyncThunk('matches/fetchMatchesHotToday', async () => {
  try {
    const response = await axios.post(`${DOMAIN}/prediction_events_all_in_one`,
      {
        type: 'single_all_in_one',
        closest: true,
      },
      {
        headers: {
          'Authorization': `Bearer =TxcSDFCXVRGVESFSFRgrs34fsdfsd`,
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const matchesHotTodaySlice = createSlice({
  name: 'matchesHotToday',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<any>) => {
    builder.addCase(fetchMatchesHotToday.pending, (state: MatchesAllEntities) => {
      state.loading = true;
    });
    builder.addCase(fetchMatchesHotToday.fulfilled, (state: MatchesAllEntities, action: any) => {
      state.loading = false;

      // Мы получаем объект с ключом 'event'
      const event = action.payload?.event;
      
      // Здесь можно проверить, что event существует и корректно обновить matches
      if (event) {
        state.matches = [event];  // Сохраняем в массив, даже если это один объект
      } else {
        state.matches = [];  // Если события нет, сбрасываем
      }

      state.error = '';
    });
    builder.addCase(fetchMatchesHotToday.rejected, (state: MatchesAllEntities, action: any) => {
      state.loading = false;
      state.matches = [];
      state.error = action.error.message;
    });
  },
});

export default matchesHotTodaySlice.reducer;
