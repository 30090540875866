// import React, { useCallback, useEffect, useMemo, useState } from 'react';
// import s from './all-events.module.scss';
// import Title from '../../elements/title/title';
// import Tabs, { TabId } from '../../elements/tabs/tabs';
// import { useAppDispatch, useAppSelector } from '../../helper/redux-hook';
// import { getIsMobile, TabsMatchEntity } from '../../common';
// import AllEventsTab from './all-events-tab';
// import soccerFieldBg from '../../../assets/soccer-field-bg.png';
// import MatchTextBlock from '../../elements/match-text-block/match-text-block';
// import Empty from '../../elements/empty/empty';
// import { getTodayDate, getTomorrowDate, getYesterdayDate } from '../../helper/get-date';
// import Leagues, { LeaguesCountryData } from '../leagues/leagues';
// import MatchItem, { matchType } from '../../elements/match-item/match-item';
// import { cn } from '../../helper/classNames';
// import { Match } from '../../../store/api.common';
// import {
//   fetchListOfChampionships,
//   fetchMatchesAllToday,
//   fetchMatchesAllTomorrow,
//   fetchMatchesAllYesterday
// } from '../../../store/slices/matches';


// const title = 'All Events';
// const allEventsDescription = '- is a pool containing all the events with the highest odds and probability, from which Hot, Gold and Jackpot were later formed. You can use the probability suggested by the algorithm for these events.';

// interface SelectedLeague {
//   country?: any;
//   league?: any;
// }

// const AllEvents: React.FC = () => {
//   const isMobile = getIsMobile();
//   const dispatch = useAppDispatch();

//   const matchesTodayAll = useAppSelector(state => state.matchesAllTodaySlice.matches);
//   const matchesYesterdayAll = useAppSelector(state => state.matchesAllYesterdaySlice.matches);
//   const matchesTomorrowAll = useAppSelector(state => state.matchesAllTomorrowSlice.matches);
//   const listOfChampionships = useAppSelector(state => state.matchesAllListOfChampionshipsSlice.list);

//   const [matchesTodayAllState, setMatchesTodayAllState] = useState([]);
//   const [matchesYesterdayAllState, setMatchesYesterdayAllState] = useState([]);
//   const [matchesTomorrowAllState, setMatchesTomorrowAllState] = useState([]);

//   const [currentTab, toggleTab] = useState<TabId>();
//   const [selectedLeague, setLeague] = useState<SelectedLeague | null>(null);

//   const date = localStorage.getItem('hourWhenReset');

//   const tabs: TabsMatchEntity[] = [
//     {
//       'title': 'Yesterday',
//       'items': matchesYesterdayAllState,
//     },
//     {
//       'title': 'Today',
//       'items': matchesTodayAllState,
//     },
//     {
//       'title': 'Tomorrow',
//       'items': matchesTomorrowAllState,
//     },
//   ];

//   const tabListIsEmpty = useMemo(() => {
//     return tabs[0].items.length === 0
//       && tabs[1].items.length === 0
//       && tabs[2].items.length === 0;
//   }, [tabs]);

//   const selectedDefaultTab = useCallback(() => {
//     if (matchesTodayAll) {
//       return toggleTab(tabs[1].title);
//     }
//     if (matchesTomorrowAll) {
//       return toggleTab(tabs[2].title);
//     }
//     if (matchesYesterdayAll) {
//       return toggleTab(tabs[0].title);
//     }
//   }, [matchesYesterdayAll, matchesTodayAll, matchesTomorrowAll]);

//   const toggleTabHandler = (id: TabId) => {
//     toggleTab(id);
//     setLeague(null);
//   };

//   useEffect(() => {
//     selectedDefaultTab();
//   }, [selectedDefaultTab]);

//   useEffect(() => {
//     if (date === null) {
//       localStorage.setItem('matchesAllToday', JSON.stringify(matchesTodayAll));
//       localStorage.setItem('matchesAllYesterday', JSON.stringify(matchesYesterdayAll));
//       localStorage.setItem('matchesAllTomorrow', JSON.stringify(matchesTomorrowAll));

//       setMatchesTodayAllState(matchesTodayAll);
//       setMatchesYesterdayAllState(matchesYesterdayAll);
//       setMatchesTomorrowAllState(matchesTomorrowAll);
//     }
//     if (date !== null) {
//       localStorage.removeItem('hourWhenReset');
//       localStorage.removeItem('matchesAllToday');
//       localStorage.removeItem('matchesAllYesterday');
//       localStorage.removeItem('matchesAllTomorrow');

//       setMatchesTodayAllState(JSON.parse(localStorage.getItem('matchesAllToday') || '{}'))
//       setMatchesYesterdayAllState(JSON.parse(localStorage.getItem('matchesAllYesterday') || '{}'))
//       setMatchesTomorrowAllState(JSON.parse(localStorage.getItem('matchesAllTomorrow') || '{}'))
//     }
//   }, [matchesTodayAll, matchesYesterdayAll, matchesTomorrowAll, date]);

//   useEffect(() => {
//     dispatch(fetchMatchesAllToday());
//     dispatch(fetchMatchesAllYesterday());
//     dispatch(fetchMatchesAllTomorrow());
//   }, [dispatch]);

//   useEffect(() => {
//     const tomorrowDate = getTomorrowDate();

//     if (tomorrowDate) {
//       dispatch(fetchListOfChampionships(tomorrowDate));
//     }
//   }, [dispatch]);

//   useEffect(() => {
//     if (currentTab === 'Yesterday') {
//       dispatch(fetchListOfChampionships(getYesterdayDate()));
//     }
//     if (currentTab === 'Today') {
//       dispatch(fetchListOfChampionships(getTodayDate()));
//     }
//     if (currentTab === 'Tomorrow') {
//       dispatch(fetchListOfChampionships(getTomorrowDate()));
//     }
//   }, [currentTab, dispatch]);

//   return (
//     <div
//       className={cn([
//         s.allEvents,
//         isMobile && s.mobile,
//       ])}
//       style={{ backgroundImage: `url(${soccerFieldBg})` }}
//     >
//       <div className="container">
//         <div className={s.allEventsInside}>
//           <div className={s.titleWrapper}>
//             <Title title={`🏆 ${title}`} />
//             {!!tabs && (
//               <div className={s.tabs}>
//                 <Tabs
//                   items={tabs}
//                   current={currentTab}
//                   onSelect={toggleTabHandler}
//                 />
//               </div>
//             )}
//           </div>
//           <Leagues
//             data={listOfChampionships as unknown as LeaguesCountryData}
//             selectedLeague={selectedLeague}
//             setSelectedLeague={setLeague}
//           />
//           {!selectedLeague && tabs.map(((tab: TabsMatchEntity) => currentTab === tab.title && !!tab.items.length && (
//             <div
//               key={tab.title}
//               className={s.items}
//             >
//               <AllEventsTab
//                 items={tab.items}
//                 title={tab.title}
//               />
//             </div>
//           )))}

//           {selectedLeague && (
//             <div className={s.items}>
//               {selectedLeague && (
//                 <>
//                   {(listOfChampionships[selectedLeague?.country]?.[selectedLeague?.league]?.matches || []).map((match: Match, index: number) => (
//                     <div
//                       className={s.itemWrapper}
//                       key={index}
//                     >
//                       <MatchItem
//                         match={match}
//                         matchType={matchType.all}
//                       />
//                     </div>
//                   ))}
//                   {!listOfChampionships[selectedLeague?.country]?.[selectedLeague?.league]?.matches && (
//                     <Empty />
//                   )}
//                 </>
//               )}
//             </div>
//           )}

//           {tabListIsEmpty && (
//             <Empty />
//           )}
//           <MatchTextBlock
//             title={title}
//             text={allEventsDescription}
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AllEvents;

// import React, { useCallback, useEffect, useState, useMemo } from 'react';
// import s from './all-events.module.scss';
// import Title from '../../elements/title/title';
// import Tabs, { TabId } from '../../elements/tabs/tabs';
// import { useAppDispatch, useAppSelector } from '../../helper/redux-hook';
// import { getIsMobile, TabsMatchEntity } from '../../common';
// import AllEventsTab from './all-events-tab';
// import soccerFieldBg from '../../../assets/soccer-field-bg.png';
// import MatchTextBlock from '../../elements/match-text-block/match-text-block';
// import Empty from '../../elements/empty/empty';
// import { getTodayDate, getTomorrowDate, getYesterdayDate } from '../../helper/get-date';
// import Leagues, { LeaguesCountryData } from '../leagues/leagues';
// import MatchItem, { matchType } from '../../elements/match-item/match-item';
// import { cn } from '../../helper/classNames';
// import { Match } from '../../../store/api.common';
// import {
//   fetchListOfChampionships,
//   fetchMatchesAllToday,
//   fetchMatchesAllTomorrow,
//   fetchMatchesAllYesterday
// } from '../../../store/slices/matches';
// import { Category } from '../../elements/tabs/tabs';

// const title = 'All events';
// const title1 = '';
// const allEventsDescription = `<br />All Events - is a pool containing all the events in the list of all evets that the our bot check per all future days.<br />
// You can use the probability suggested by the algorithm for these events in FREE view.<br><br><br><br />`;
// // const allEventsHtmlDescription = `
// //   All Events - is a pool containing all the events with the highest odds and probability, 
// //   from which Hot, Gold and Jackpot were later formed.<br />
// //   You can use the probability suggested by the algorithm for these events.
// // `;

// interface SelectedLeague {
//   country?: any;
//   league?: any;
// }

// const AllEvents: React.FC = () => {
//   const isMobile = getIsMobile();
//   const dispatch = useAppDispatch();

//   const matchesTodayAll = useAppSelector(state => state.matchesAllTodaySlice.matches);
//   const matchesYesterdayAll = useAppSelector(state => state.matchesAllYesterdaySlice.matches);
//   const matchesTomorrowAll = useAppSelector(state => state.matchesAllTomorrowSlice.matches);
//   const listOfChampionships = useAppSelector(state => state.matchesAllListOfChampionshipsSlice.list);

//   const [matchesTodayAllState, setMatchesTodayAllState] = useState([]);
//   const [matchesYesterdayAllState, setMatchesYesterdayAllState] = useState([]);
//   const [matchesTomorrowAllState, setMatchesTomorrowAllState] = useState([]);

//   const [currentTab, toggleTab] = useState<TabId>();
//   const [selectedLeague, setLeague] = useState<SelectedLeague | null>(null);

//   const date = localStorage.getItem('hourWhenReset');

//   const category: Category = 'ALL EVENTS';  // Передаем категорию 'ALL EVENTS'

//   const tabs: TabsMatchEntity[] = [
//     {
//       'title': 'Yesterday',
//       'items': matchesYesterdayAllState,
//     },
//     {
//       'title': 'Today',
//       'items': matchesTodayAllState,
//     },
//     {
//       'title': 'Tomorrow',
//       'items': matchesTomorrowAllState,
//     },
//   ];

//   const tabListIsEmpty = useMemo(() => {
//     return tabs[0].items.length === 0
//       && tabs[1].items.length === 0
//       && tabs[2].items.length === 0;
//   }, [tabs]);

//   const selectedDefaultTab = useCallback(() => {
//     if (matchesTodayAll) {
//       return toggleTab(tabs[1].title);
//     }
//     if (matchesTomorrowAll) {
//       return toggleTab(tabs[2].title);
//     }
//     if (matchesYesterdayAll) {
//       return toggleTab(tabs[0].title);
//     }
//   }, [matchesYesterdayAll, matchesTodayAll, matchesTomorrowAll]);

//   const toggleTabHandler = (id: TabId) => {
//     toggleTab(id);
//     setLeague(null);
//   };

//   useEffect(() => {
//     selectedDefaultTab();
//   }, [selectedDefaultTab]);

//   useEffect(() => {
//     if (date === null) {
//       localStorage.setItem('matchesAllToday', JSON.stringify(matchesTodayAll));
//       localStorage.setItem('matchesAllYesterday', JSON.stringify(matchesYesterdayAll));
//       localStorage.setItem('matchesAllTomorrow', JSON.stringify(matchesTomorrowAll));

//       setMatchesTodayAllState(matchesTodayAll);
//       setMatchesYesterdayAllState(matchesYesterdayAll);
//       setMatchesTomorrowAllState(matchesTomorrowAll);
//     }
//     if (date !== null) {
//       localStorage.removeItem('hourWhenReset');
//       localStorage.removeItem('matchesAllToday');
//       localStorage.removeItem('matchesAllYesterday');
//       localStorage.removeItem('matchesAllTomorrow');

//       setMatchesTodayAllState(JSON.parse(localStorage.getItem('matchesAllToday') || '{}'))
//       setMatchesYesterdayAllState(JSON.parse(localStorage.getItem('matchesAllYesterday') || '{}'))
//       setMatchesTomorrowAllState(JSON.parse(localStorage.getItem('matchesAllTomorrow') || '{}'))
//     }
//   }, [matchesTodayAll, matchesYesterdayAll, matchesTomorrowAll, date]);

//   useEffect(() => {
//     dispatch(fetchMatchesAllToday());
//     dispatch(fetchMatchesAllYesterday());
//     dispatch(fetchMatchesAllTomorrow());
//   }, [dispatch]);

//   useEffect(() => {
//     const tomorrowDate = getTomorrowDate();

//     if (tomorrowDate) {
//       dispatch(fetchListOfChampionships(tomorrowDate));
//     }
//   }, [dispatch]);

//   useEffect(() => {
//     if (currentTab === 'Yesterday') {
//       dispatch(fetchListOfChampionships(getYesterdayDate()));
//     }
//     if (currentTab === 'Today') {
//       dispatch(fetchListOfChampionships(getTodayDate()));
//     }
//     if (currentTab === 'Tomorrow') {
//       dispatch(fetchListOfChampionships(getTomorrowDate()));
//     }
//   }, [currentTab, dispatch]);

//   return (
//     <div
//       className={cn([
//         s.allEvents,
//         isMobile && s.mobile,
//       ])}
//       style={{ backgroundImage: `url(${soccerFieldBg})` }}
//     >
//       <div className="container">
//         <div className={s.allEventsInside}>
//           <div className={s.titleWrapper}>
//             <Title title={`🏆 ${title}`} />
//             {!!tabs && (
//               <div className={s.tabs}>
//                 <Tabs
//                   items={tabs}
//                   current={currentTab}
//                   onSelect={toggleTabHandler}
//                   category={category}  // Передаем категорию 'ALL EVENTS'
//                 />
//               </div>
//             )}
//           </div>
//           <Leagues
//             data={listOfChampionships as unknown as LeaguesCountryData}
//             selectedLeague={selectedLeague}
//             setSelectedLeague={setLeague}
//           />
//           {!selectedLeague && tabs.map(((tab: TabsMatchEntity) => currentTab === tab.title && !!tab.items.length && (
//             <div
//               key={tab.title}
//               className={s.items}
//             >
//               <AllEventsTab
//                 items={tab.items}
//                 title={tab.title}
//               />
//             </div>
//           )))}

//           {selectedLeague && (
//             <div className={s.items}>
//               {selectedLeague && (
//                 <>
//                   {(listOfChampionships[selectedLeague?.country]?.[selectedLeague?.league]?.matches || []).map((match: Match, index: number) => (
//                     <div
//                       className={s.itemWrapper}
//                       key={index}
//                     >
//                       <MatchItem
//                         match={match}
//                         matchType={matchType.all}
//                       />
//                     </div>
//                   ))}
//                   {!listOfChampionships[selectedLeague?.country]?.[selectedLeague?.league]?.matches && (
//                     <Empty />
//                   )}
//                 </>
//               )}
//             </div>
//           )}

//           {tabListIsEmpty && (
//             <Empty />
//           )}

//           {/* Передаем htmlContent вместо text */}
//           <MatchTextBlock title={title1} htmlContent={allEventsDescription}/>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AllEvents;

import React, { useCallback, useEffect, useState, useMemo } from 'react';
import s from './all-events.module.scss';
import Title from '../../elements/title/title';
import Tabs, { TabId } from '../../elements/tabs/tabs';
import { useAppDispatch, useAppSelector } from '../../helper/redux-hook';
import { getIsMobile, TabsMatchEntity } from '../../common';
import AllEventsTab from './all-events-tab';
import soccerFieldBg from '../../../assets/soccer-field-bg.png';
import MatchTextBlock from '../../elements/match-text-block/match-text-block';
import Empty from '../../elements/empty/empty';
import { getTodayDate, getTomorrowDate, getYesterdayDate } from '../../helper/get-date';
import Leagues, { LeaguesCountryData } from '../leagues/leagues';
import MatchItem, { matchType } from '../../elements/match-item/match-item';
import { cn } from '../../helper/classNames';
import { Match } from '../../../store/api.common';
import {
  fetchListOfChampionships,
  fetchMatchesAllToday,
  fetchMatchesAllTomorrow,
  fetchMatchesAllYesterday
} from '../../../store/slices/matches';
import { Category } from '../../elements/tabs/tabs';
import SubscribeButton from '../../elements/subscribe/subscribe-button';

const title = 'All events';
const title1 = '';
const allEventsDescription = `<br>All Events - is a pool containing all the events in the list of all evets that the our bot check per all future days.<br />
You can use the probability suggested by the algorithm for these events in FREE view.<br><br />`;

interface SelectedLeague {
  country?: any;
  league?: any;
}

const AllEvents: React.FC = () => {
  const isMobile = getIsMobile();
  const dispatch = useAppDispatch();

  const matchesTodayAll = useAppSelector(state => state.matchesAllTodaySlice.matches);
  const matchesYesterdayAll = useAppSelector(state => state.matchesAllYesterdaySlice.matches);
  const matchesTomorrowAll = useAppSelector(state => state.matchesAllTomorrowSlice.matches);
  const listOfChampionships = useAppSelector(state => state.matchesAllListOfChampionshipsSlice.list);

  const [matchesTodayAllState, setMatchesTodayAllState] = useState([]);
  const [matchesYesterdayAllState, setMatchesYesterdayAllState] = useState([]);
  const [matchesTomorrowAllState, setMatchesTomorrowAllState] = useState([]);

  const [currentTab, toggleTab] = useState<TabId>();
  const [selectedLeague, setLeague] = useState<SelectedLeague | null>(null);

  const date = localStorage.getItem('hourWhenReset');

  const category: Category = 'ALL EVENTS';

  const tabs: TabsMatchEntity[] = [
    {
      'title': 'Yesterday',
      'items': matchesYesterdayAllState,
    },
    {
      'title': 'Today',
      'items': matchesTodayAllState,
    },
    {
      'title': 'Tomorrow',
      'items': matchesTomorrowAllState,
    },
  ];

  const tabListIsEmpty = useMemo(() => {
    return tabs[0].items.length === 0
      && tabs[1].items.length === 0
      && tabs[2].items.length === 0;
  }, [tabs]);

  const selectedDefaultTab = useCallback(() => {
    if (matchesTodayAll) {
      return toggleTab(tabs[1].title);
    }
    if (matchesTomorrowAll) {
      return toggleTab(tabs[2].title);
    }
    if (matchesYesterdayAll) {
      return toggleTab(tabs[0].title);
    }
  }, [matchesYesterdayAll, matchesTodayAll, matchesTomorrowAll]);

  const toggleTabHandler = (id: TabId) => {
    toggleTab(id);
    setLeague(null);
  };

  useEffect(() => {
    selectedDefaultTab();
  }, [selectedDefaultTab]);

  useEffect(() => {
    if (date === null) {
      localStorage.setItem('matchesAllToday', JSON.stringify(matchesTodayAll));
      localStorage.setItem('matchesAllYesterday', JSON.stringify(matchesYesterdayAll));
      localStorage.setItem('matchesAllTomorrow', JSON.stringify(matchesTomorrowAll));

      setMatchesTodayAllState(matchesTodayAll);
      setMatchesYesterdayAllState(matchesYesterdayAll);
      setMatchesTomorrowAllState(matchesTomorrowAll);
    }
    if (date !== null) {
      localStorage.removeItem('hourWhenReset');
      localStorage.removeItem('matchesAllToday');
      localStorage.removeItem('matchesAllYesterday');
      localStorage.removeItem('matchesAllTomorrow');

      setMatchesTodayAllState(JSON.parse(localStorage.getItem('matchesAllToday') || '{}'))
      setMatchesYesterdayAllState(JSON.parse(localStorage.getItem('matchesAllYesterday') || '{}'))
      setMatchesTomorrowAllState(JSON.parse(localStorage.getItem('matchesAllTomorrow') || '{}'))
    }
  }, [matchesTodayAll, matchesYesterdayAll, matchesTomorrowAll, date]);

  useEffect(() => {
    dispatch(fetchMatchesAllToday());
    dispatch(fetchMatchesAllYesterday());
    dispatch(fetchMatchesAllTomorrow());
  }, [dispatch]);

  useEffect(() => {
    const tomorrowDate = getTomorrowDate();
    if (tomorrowDate) {
      dispatch(fetchListOfChampionships(tomorrowDate));
    }
  }, [dispatch]);

  useEffect(() => {
    if (currentTab === 'Yesterday') {
      dispatch(fetchListOfChampionships(getYesterdayDate()));
    }
    if (currentTab === 'Today') {
      dispatch(fetchListOfChampionships(getTodayDate()));
    }
    if (currentTab === 'Tomorrow') {
      dispatch(fetchListOfChampionships(getTomorrowDate()));
    }
  }, [currentTab, dispatch]);

  return (
    <div
      className={cn([
        s.allEvents,
        isMobile && s.mobile,
      ])}
      style={{ backgroundImage: `url(${soccerFieldBg})` }}
    >
      <div className="container">
        <div className={s.allEventsInside}>
          <div className={s.titleWrapper}>
            <Title title={`🏆 ${title}`} />
            {!!tabs && (
              <div className={s.tabs}>
                <Tabs
                  items={tabs}
                  current={currentTab}
                  onSelect={toggleTabHandler}
                  category={category}
                />
              </div>
            )}
          </div>
          <Leagues
            data={listOfChampionships as unknown as LeaguesCountryData}
            selectedLeague={selectedLeague}
            setSelectedLeague={setLeague}
          />
          {!selectedLeague && tabs.map(((tab: TabsMatchEntity) => currentTab === tab.title && !!tab.items.length && (
            <div
              key={tab.title}
              className={s.items}
            >
              <AllEventsTab
                items={tab.items}
                title={tab.title}
              />
            </div>
          )))}

          {selectedLeague && (
            <div className={s.items}>
              {selectedLeague && (
                <>
                  {(listOfChampionships[selectedLeague?.country]?.[selectedLeague?.league]?.matches || []).map((match: Match, index: number) => (
                    <div
                      className={s.itemWrapper}
                      key={index}
                    >
                      <MatchItem
                        match={match}
                        matchType={matchType.all}
                      />
                    </div>
                  ))}
                  {!listOfChampionships[selectedLeague?.country]?.[selectedLeague?.league]?.matches && (
                    <Empty />
                  )}
                </>
              )}
            </div>
          )}

          {tabListIsEmpty && (
            <Empty />
          )}
          {/* Передаем htmlContent вместо text */}
          <MatchTextBlock title={title1} htmlContent={allEventsDescription}/>
          {/* Используем компонент SubscribeButton */}
          <div className={s.subscribeButtonWrapper}>
            <SubscribeButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllEvents;
