// import React from 'react';
// import s from './tabs.module.scss';
// import { cn } from '../../helper/classNames';
// import { TabsMatchEntity } from '../../common';


// export type TabId = string | number | undefined;

// interface TabsSwitcherProps {
//   current: TabId;
//   items: any;
//   onSelect: (id: number | string) => void;
// }

// const Tabs: React.FC<TabsSwitcherProps> = (
//   {
//     current,
//     items,
//     onSelect,
//   }
// ) => {
//   return (
//     <div className={s.tabs}>
//       {items.map((item: TabsMatchEntity) => (
//         <div
//           className={s.tabWrapper}
//           key={item.title}
//         >
//           <div
//             className={cn([
//               s.tab,
//               current === item.title && s.active,
//               /!item.items.length && s.disabled,
//             ])}
//             onClick={() => onSelect(item.title)}
//           >
//             <div className={s.title}>
//               {item.title}
//             </div>
//             <div className={s.count}>
//               {item.items.length || 0}
//             </div>
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// };

// // Тип для данных, которые нужно получить
// interface ApiResponse {
//   // id_all_single_one_plus_24: number;
//   id_all_in_one_plus_24: number;
//   // id_all_plus_24: number;
//   // id_pred_plus_24: number;
//   // id_pred_single_plus_24: number;
//   // id_live_plus_24: number;
//   // id_all_single_one_plus_48: number;
//   id_all_in_one_plus_48: number;
//   // id_all_plus_48: number;
//   // id_pred_plus_48: number;
//   // id_pred_single_plus_48: number;
//   // id_all_single_one_plus_72: number;
//   id_all_in_one_plus_72: number;
//   // id_all_plus_72: number;
//   // id_pred_plus_72: number;
//   // id_pred_single_plus_72: number;
//   // id_all_single_plus_93: number;
//   // id_all_plus_93: number;
//   // id_pred_plus_93: number;
//   // id_pred_single_plus_93: number;
//   // id_all_single_one_minus_24: number;
//   // id_all_in_one_minus_24: number;
//   // id_all_minus_24: number;
//   // id_pred_minus_24: number;
//   // id_pred_single_minus_24: number;
//   // id_all_single_plus_72: number;
//   // id_all_single_plus_48: number;
//   // id_all_single_plus_24: number;
//   // id_all_single_minus_24: number;
// }
// tabs.tsx
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import s from './tabs.module.scss';
import { cn } from '../../helper/classNames';

export type TabId = string | number | undefined;
export type Category = 'HOT EVENTS' | 'GOLD EVENTS' | 'ALL EVENTS';

interface TabsSwitcherProps {
  current: TabId;
  onSelect: (id: TabId) => void;
  items: any[]; // Передаем переменные, а не массивы
}

// Тип для данных, которые нужно получить
interface ApiResponse {
  id_all_single_one_plus_24: number;
  id_all_single_one_plus_48: number;
  id_all_single_one_plus_72: number;
  id_all_in_one_plus_24: number;
  id_all_in_one_plus_48: number;
  id_all_in_one_plus_72: number;
  id_all_minus_24: number;
  id_all_plus_24: number;
  id_all_plus_48: number;
  id_all_plus_72: number;
}

// Тип для категорий
// type Category = 'HOT EVENTS' | 'GOLD EVENTS' | 'ALL EVENTS';

const Tabs: React.FC<TabsSwitcherProps & { category: Category }> = ({ current, onSelect, items, category }) => {
  const [data, setData] = useState<ApiResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://total93admin.com/prediction_hot_events_for_telegram/all_numbers', {
          headers: {
            'Authorization': `Bearer =TxcSDFCXVRGVESFSFRgrs34fsdfsd`,
          },
        });
        setData(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch data');
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  const tabsData: { [key in Category]: { title: string; count: number }[] } = {
    'HOT EVENTS': [
      { title: 'Today', count: data?.id_all_single_one_plus_24 || 0 },
      { title: 'Tomorrow', count: data?.id_all_single_one_plus_48 || 0 },
      { title: 'After tomorrow', count: data?.id_all_single_one_plus_72 || 0 },
    ],
    'GOLD EVENTS': [
      { title: 'Today', count: data?.id_all_in_one_plus_24 || 0 },
      { title: 'Tomorrow', count: data?.id_all_in_one_plus_48 || 0 },
      { title: 'After tomorrow', count: data?.id_all_in_one_plus_72 || 0 },
    ],
    'ALL EVENTS': [
      { title: 'Yesterday', count: data?.id_all_minus_24 || 0 },
      { title: 'Today', count: data?.id_all_plus_24 || 0 },
      { title: 'Tomorrow', count: data?.id_all_plus_48 || 0 },
      // { title: 'After tomorrow', count: data?.id_all_plus_72 || 0 },
    ],
  };

  const filteredTabs = tabsData[category]; // Оставляем только нужные табы для данной категории

  return (
    <div className={s.tabs}>
      {filteredTabs.map((item) => (
        <div className={s.tabWrapper} key={item.title}>
          <div
            className={cn([
              s.tab,
              current === item.title && s.active,
              !item.count && s.disabled,
            ])}
            onClick={() => onSelect(item.title)}
          >
            <div className={s.title}>
              {item.title}
            </div>
            <div className={s.count}>
              {item.count}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Tabs;
