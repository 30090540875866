import React from 'react';
import s from './match-item.module.scss';
import matchAllBgImg from '../../../assets/match/events-all-bg.png';
import matchGoldBgImg from '../../../assets/match/events-gold-bg.png';
import matchHotBgImg from '../../../assets/match/events-hot-bg.png';
import matchJackpotBgImg from '../../../assets/match/events-jackpot-bg.png';
import matchVsImg from '../../../assets/match-vs.png';
import Status from '../status/status';
import { cn } from '../../helper/classNames';
import Button from '../button/button';
import bonusIcon from '../../../assets/bonus.png';
import { useAppDispatch } from '../../helper/redux-hook';
import { CustomerBasicModal, customerOpenBasicModal } from '../../../store/slices/customerSlice';
import { Match, MatchStatusType } from '../../../store/api.common';


export enum matchType {
  hot = 'hot',
  gold = 'gold',
  jackpot = 'jackpot',
  all = 'all',
}

const matchTypeImageBg = {
  [matchType.hot]: matchHotBgImg,
  [matchType.gold]: matchGoldBgImg,
  [matchType.jackpot]: matchJackpotBgImg,
  [matchType.all]: matchAllBgImg,
};

export const itemTypeClassMap = {
  [matchType.hot]: s.hot,
  [matchType.gold]: s.gold,
  [matchType.jackpot]: s.jackpot,
  [matchType.all]: s.all,
}

export const itemStatusClassMap = {
  [MatchStatusType.notStarted]: s.statusNotStarted,
  [MatchStatusType.finished]: s.statusFinished,
  [MatchStatusType.live]: s.statusLive,
}

export interface MatchItemProps {
  match: Match,
  matchType?: matchType,
}

// export interface MatchItemProps {
//   match: Match;
//   matchType?: matchType;
//   matchCount?: number; // Теперь matchCount необязателен (чтобы не ломало другие вызовы)
// }

const MatchItem: React.FC<MatchItemProps> = (
  {
    match,
    matchType,
  }
) => {
  const dispatch = useAppDispatch();

  const {
    match_country_name,
    league_name,
    league_logo_path,
    prediction,
    match_country_image_path,
    date,
    time,
    localTeam,
    visitorTeam,
    localTeam_logo,
    visitorTeam_logo,
    localteam_score,
    visitorteam_score,
    rez_1_score,
    rez_2_score,
    rez_3_score,
    status
  } = match || {};

  const [day, month, year] = date?.split('-') || [];

  const openModalGetBonus = () => {
    dispatch(customerOpenBasicModal(CustomerBasicModal.getBonus))
  };

  return (
    <div
      className={cn([
        s.itemWrapper,
        !!status && itemStatusClassMap[status],
      ])}
    >
      <div
        className={cn([
          s.item,
          matchType && itemTypeClassMap[matchType],
        ])}
        style={{ backgroundImage: `url(${matchType ? matchTypeImageBg[matchType] : ''})` }}
      >
        <div className={s.content}>
          <div className={s.header}>
            <div className={s.headerLeft}>
              <div className={s.leagueImgWrapper}>
                {league_logo_path && (
                  <img
                    src={league_logo_path}
                    alt={league_name}
                    className={s.leagueImg}
                  />
                )}
              </div>

              <div className={s.leagueInfo}>
                <div className={s.leagueName}>
                  {league_name}
                </div>
                <div className={s.leagueContry}>
                  <div className={s.leagueContryImgWrapper}>
                    {match_country_image_path && (
                      <img
                        src={match_country_image_path}
                        alt={match_country_name}
                        className={s.leagueContryImg}
                      />
                    )}
                  </div>
                  <div className={s.leagueContryName}>
                    {match_country_name}
                  </div>
                </div>
              </div>
            </div>
            <div className={s.headerRight}>
              <div className={s.date}>
                {day}
                &nbsp;
                {new Date(+year, +month - 1).toLocaleString("en-US", { month: "short" })}
                &nbsp;
                {time}
              </div>
              {status && (
                <div className={s.status}>
                  <Status status={status} />
                </div>
              )}
            </div>
          </div>
          <div className={s.body}>
            <div className={s.bodyInside}>
              <div className={s.bodyInsideLeft}>
                <div className={s.teams}>
                  <div className={s.team}>
                    <div className={s.teamImgWrapper}>
                      {localTeam_logo && (
                        <img
                          src={localTeam_logo}
                          alt={localTeam}
                        />
                      )}
                    </div>
                    <div className={s.teamName}>
                      {localTeam}
                    </div>
                  </div>

                  <div className={s.team}>
                    <div className={s.teamImgWrapper}>
                      {visitorTeam_logo && (
                        <img
                          src={visitorTeam_logo}
                          alt={visitorTeam}
                        />
                      )}
                    </div>
                    <div className={s.teamName}>
                      {visitorTeam}
                    </div>
                  </div>
                </div>
              </div>

              <div className={s.bodyInsideRight}>
                {status !== MatchStatusType.notStarted && (
                  <div className={s.scoreMatchWrapper}>
                    <div className={s.scoreMatch}>
                      {localteam_score}
                    </div>
                    <div className={s.scoreMatchLine} />
                    <div className={s.scoreMatch}>
                      {visitorteam_score}
                    </div>
                  </div>
                )}
                {status === MatchStatusType.notStarted && (
                  <img src={matchVsImg} alt="Match Vs" />
                )}
              </div>
            </div>

            <div className={s.labelWrapper}>
              <div className={s.label}>
                {prediction || 'Machine learning view'}
              </div>
            </div>
          </div>
        </div>

        <div className={s.footer}>
          {rez_1_score && (
            <div className={s.footerItem}>
              {rez_1_score.toFixed(0)}%
            </div>
          )}
          {rez_3_score && (
            <div className={s.footerItem}>
              {rez_3_score.toFixed(0)}%
            </div>
          )}
          {rez_2_score && (
            <div className={s.footerItem}>
              {rez_2_score.toFixed(0)}%
            </div>
          )}
        </div>
      </div>

      <div
        className={s.getBonus}
      >
        <Button
          text="Get bonus"
          modifiers={['main']}
          icon={bonusIcon}
          clickHandler={openModalGetBonus}
        />
      </div>
    </div>
  );
};

export default MatchItem;
