import React, { useLayoutEffect, useMemo } from 'react';
import s from './dragger-scroll.module.scss';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import { LeftArrow, RightArrow } from './dragger-scroll-arrows';
import { cn } from '../../helper/classNames';
import { useDrag } from '../../helper/drag.hook';


type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

interface DraggedScrollItemProps {
  itemId: string | number | null;
  children: React.ReactElement;
  dragging: boolean;
  classNames?: string[];
  onClick: (context: scrollVisibilityApiType) => void;
}

const DraggedScrollItem = (
  {
    itemId,
    children,
    dragging,
    classNames = [],
    onClick,
  }: DraggedScrollItemProps,
) => {
  const visibility = React.useContext(VisibilityContext);

  const handleClick = () => {
    if (itemId) {
      const visible = visibility.isItemVisible(itemId.toString());
      if (!visible) {
        onClick(visibility);
      }
    }
  };

  return (
    <div
      className={cn(classNames)}
      onClick={handleClick}
      style={{ pointerEvents: dragging ? 'none' : 'auto' }}
    >
      {children}
    </div>
  );
};

function onWheel(apiObj: scrollVisibilityApiType, ev: React.WheelEvent, rtl: boolean): void {
  const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

  if (isThouchpad) {
    ev.stopPropagation();
    return;
  }

  const scrollNext = rtl ? apiObj.scrollPrev : apiObj.scrollNext;
  const scrollPrev = rtl ? apiObj.scrollNext : apiObj.scrollPrev;

  if (ev.deltaY < 0) {
    scrollPrev();
  }
  if (ev.deltaY > 0) {
    scrollNext();
  }
}

interface DraggedScrollProps {
  arrows?: boolean;
  arrowIcon?: React.ComponentType<any>;
  hideSingleArrow?: boolean;
  classNames?: string[];
  children: React.ReactNodeArray;
  apiRef?: React.MutableRefObject<any>;
  wheelScroll?: boolean;
  itemClassNames?: string[];
  rtl?: boolean;
}

export const DraggedScroll: React.FC<DraggedScrollProps> = (
  {
    children,
    arrows = true,
    arrowIcon,
    hideSingleArrow = true,
    wheelScroll = true,
    classNames = [],
    apiRef,
    itemClassNames,
    rtl = false,
  },
) => {
  const ArrowLeft = useMemo(() => {
    if (arrows) {
      return rtl ? (
        <RightArrow
          icon={arrowIcon}
          hideSingleArrow={hideSingleArrow}
        />
      ) : (
        <LeftArrow
          icon={arrowIcon}
          hideSingleArrow={hideSingleArrow}
        />
      );
    }

    return null;
  }, [rtl, arrows, arrowIcon, hideSingleArrow]);

  const ArrowRight = useMemo(() => {
    if (arrows) {
      return rtl ? (
        <LeftArrow
          icon={arrowIcon}
          hideSingleArrow={hideSingleArrow}
        />
      ) : (
        <RightArrow
          icon={arrowIcon}
          hideSingleArrow={hideSingleArrow}
        />
      );
    }

    return null;
  }, [rtl, arrows, arrowIcon, hideSingleArrow]);

  const {
    dragStart, dragStop, dragMove, dragging,
  } = useDrag();
  const handleDrag = ({ scrollContainer }: scrollVisibilityApiType) =>
    (ev: React.MouseEvent) =>
      dragMove(ev, (posDiff) => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollLeft += posDiff;
        }
      });

  const handleClick = (itemId: string | number | null) =>
    ({ getItemById, scrollToItem }: scrollVisibilityApiType) => {
      if (dragging) {
        return false;
      }
      if (itemId) {
        scrollToItem(getItemById(itemId.toString()), 'smooth', 'end', 'nearest');
      }
    };

  const childrenMap = React.Children.toArray(children);

  const onWheelHandler = React.useCallback((apiObj: scrollVisibilityApiType, ev: React.WheelEvent) => onWheel(apiObj, ev, rtl), [
    rtl,
  ]);

  const scrollContainer = apiRef?.current?.scrollContainer?.current;

  useLayoutEffect(() => {
    if (rtl && scrollContainer) {
      scrollContainer.scrollLeft = 99999;
    }
  }, [scrollContainer?.scrollWidth, rtl]);

  return (
    <div className="horizontal-menu" data-test="horizontalSportMenu">
      <div
        className={cn([
          'scroll-container__wrapper',
          ...classNames,
          arrows ? 'menu-wrapper arrows-enabled' : 'menu-wrapper',
        ])}
        onMouseLeave={dragStop}
      >
        <ScrollMenu
          apiRef={apiRef}
          LeftArrow={ArrowLeft}
          RightArrow={ArrowRight}
          wrapperClassName={cn([s.wrapper])}
          onWheel={wheelScroll ? onWheelHandler : undefined}
          onMouseDown={() => dragStart}
          onMouseUp={() => dragStop}
          onMouseMove={handleDrag}
        >
          {childrenMap.map((child: any) => (
            <DraggedScrollItem
              key={child.key}
              onClick={() => handleClick(child.key)}
              itemId={child.key}
              dragging={dragging}
              classNames={itemClassNames}
            >
              {child}
            </DraggedScrollItem>
          ))}
        </ScrollMenu>
      </div>
    </div>
  );
};
