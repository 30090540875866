export const DOMAIN = 'https://total93admin.com';


export enum MatchStatusType {
  notStarted = 'Not started',
  finished = 'Finished',
  live = 'Live',
}

export interface Match {
  date?: string;
  time?: string;
  status?: MatchStatusType | null;
  minute?: number | null;
  ht_score?: number | null;
  ft_score?: number | null;
  match_country_name?: string;
  league_name?: string;
  localTeam?: string;
  visitorTeam?: string;
  localteam_score?: number;
  visitorteam_score?: number;
  match_country_image_path?: string;
  league_logo_path?: string;
  localTeam_logo?: string;
  visitorTeam_logo?: string;
  rez_1_score?: number;
  rez_3_score?: number;
  rez_2_score?: number;
  prediction?: string;
  cards?: never[];
}

export interface MatchesAllEntities {
  matches?: Match[],
  loading: boolean,
  error: string,
}

interface MatchesByCountry {
  [country: string]: any;
}

export interface ListOfChampionshipsProps {
  list: MatchesByCountry[];
  loading: boolean;
  error: string;
}

export interface ListOfChampionshipsEntities {
  list: MatchesByCountry[];
  loading: boolean,
  error: string,
}
