import type { FC } from 'react';
import { useEffect } from 'react';
import { useAppDispatch } from '../helper/redux-hook';
import AllEvents from '../sections/all-events/all-events';
import BallStars from '../sections/ball-stars/ball-stars';
import HotEvents from '../sections/hot-events/hot-events';
import GoldEvents from '../sections/gold-events/gold-events';
import JackpotEvents from '../sections/jackpot-events/jackpot-events';
import InfoAbout from '../sections/info-about/info-about';


const Home: FC = () => {
  const dispatch = useAppDispatch();
  const dateLocalStorage = JSON.parse(localStorage.getItem('hourWhenReset') || '{}');

  useEffect(() => {
    setTimeout(() => {
      localStorage.setItem('hourWhenReset', JSON.stringify(+new Date() + (3600 * 1000 * (3 - +new Date().getHours()))));
    }, 5000);
  }, [dateLocalStorage, dispatch]);

  return (
    <>
      <BallStars />
      <JackpotEvents />
      <GoldEvents />
      <HotEvents />
      <InfoAbout />
      <AllEvents />
    </>
  );
};

export default Home;
