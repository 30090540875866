import { configureStore } from '@reduxjs/toolkit'

import customerSlice from './slices/customerSlice';

import matchesAllTodaySlice from './slices/matches/matchesAll/matchesAllTodaySlice';
import matchesAllYesterdaySlice from './slices/matches/matchesAll/matchesAllYesterdaySlice';
import matchesAllTomorrowSlice from './slices/matches/matchesAll/matchesAllTomorrowSlice';
import matchesAllListOfChampionshipsSlice from './slices/matches/matchesAll/matchesAllListOfChampionshipsSlice';

import matchesGoldTomorrowSlice from './slices/matches/matchesGold/matchesGoldTomorrowSlice';
import matchesGoldTodaySlice from './slices/matches/matchesGold/matchesGoldTodaySlice';
import matchesGoldYesterdaySlice from './slices/matches/matchesGold/matchesGoldYesterdaySlice';
import matchesGoldAfterTomorrowSlice from './slices/matches/matchesGold/matchesGoldAfterTomorrowSlice';

import matchesHotYesterday from './slices/matches/matchesHot/matchesHotYesterdaySlice';
import matchesHotToday from './slices/matches/matchesHot/matchesHotTodaySlice';
import matchesHotTomorrow from './slices/matches/matchesHot/matchesHotTomorrowSlice';
import matchesHotAfterTomorrow from './slices/matches/matchesHot/matchesHotAfterTomorrowSlice';

import matchesJackpotSlice from './slices/matches/matchesJackpot/matchesJackpotSlice';
import InfoAbout from './slices/matches/infoAbout/infoAboutSlice';

import moreCount from './slices/tabShowMoreSlice';

export const store = configureStore({
  reducer: {
    customerSlice,

    matchesAllTodaySlice,
    matchesAllYesterdaySlice,
    matchesAllTomorrowSlice,

    matchesGoldTodaySlice,
    matchesGoldYesterdaySlice,
    matchesGoldTomorrowSlice,
    matchesGoldAfterTomorrowSlice,

    matchesHotYesterday,
    matchesHotToday,
    matchesHotTomorrow,
    matchesHotAfterTomorrow,

    matchesJackpotSlice,

    InfoAbout,

    matchesAllListOfChampionshipsSlice,

    moreCount,
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
