import { Match } from '../../store/api.common';

declare global {
  interface Window {
    isMobile: boolean;
  }
}

export interface TabsMatchEntity {
  title: string,
  items: Match[],
}

export const getBonus = () => {
  window.open('https://betfury.bet/d59521857', '_blank');
};

export const getIsMobile = () => window.isMobile;
