import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import { DOMAIN, MatchesAllEntities } from '../../../api.common';
import { getTomorrowDate } from '../../../../app/helper/get-date';


const initialState = {
  matches: [],
  loading: false,
  error: '',
}

export const fetchMatchesAllTomorrow = createAsyncThunk('matches/fetchMatchesAllTomorrow', () => {
  return axios
    .get(`${DOMAIN}/prediction_events/${getTomorrowDate()}`, {
      headers: {
        'Authorization': `Bearer =TxcSDFCXVRGVESFSFRgrs34fsdfsd`
      }
    })
    .then((response) => response.data);
});

export const matchesAllTomorrowSlice = createSlice({
  name: 'matchesAllTomorrow',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<any>) => {
    builder.addCase(fetchMatchesAllTomorrow.pending, (state: MatchesAllEntities) => {
      state.loading = true;
    });
    builder.addCase(fetchMatchesAllTomorrow.fulfilled, (state: MatchesAllEntities, action: any) => {
      state.matches = action.payload;
      state.loading = false;
      state.error = '';
    });
    builder.addCase(fetchMatchesAllTomorrow.rejected, (state: MatchesAllEntities, action: any) => {
      state.matches = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default matchesAllTomorrowSlice.reducer
