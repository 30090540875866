// import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// import axios from 'axios';
// import { DOMAIN, MatchesAllEntities } from '../../../api.common';
// import { getYesterdayDate } from '../../../../app/helper/get-date';


// const initialState = {
//   matches: [],
//   loading: false,
//   error: '',
// };

// export const fetchMatchesHotYesterday = createAsyncThunk('matches/fetchMatchesHotYesterday', () => {
//   return axios
//     .get(`${DOMAIN}/prediction_hot_events/${getYesterdayDate()}`, {
//       headers: {
//         'Authorization': `Bearer =TxcSDFCXVRGVESFSFRgrs34fsdfsd`
//       }
//     })
//     .then((response) => response.data)
// })

// export const matchesHotYesterdaySlice = createSlice({
//   name: 'matchesHotYesterday',
//   initialState,
//   reducers: {},
//   extraReducers: (builder: ActionReducerMapBuilder<any>) => {
//     builder.addCase(fetchMatchesHotYesterday.pending, (state: MatchesAllEntities) => {
//       state.loading = true;
//     });
//     builder.addCase(fetchMatchesHotYesterday.fulfilled, (state: MatchesAllEntities, action: any) => {
//       state.loading = false;
//       state.matches = action.payload;
//       state.error = '';
//     });
//     builder.addCase(fetchMatchesHotYesterday.rejected, (state: MatchesAllEntities, action: any) => {
//       state.loading = false;
//       state.matches = [];
//       state.error = action.error.message;
//     });
//   },
// });

// export default matchesHotYesterdaySlice.reducer
import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { DOMAIN, MatchesAllEntities } from '../../../api.common';

const initialState = {
  matches: [],
  loading: false,
  error: '',
};

export const fetchMatchesHotAfterTomorrow = createAsyncThunk('matches/fetchMatchesHotAfterTomorrow', async () => {
  try {
    const response = await axios.post(`${DOMAIN}/prediction_events_all_in_one`,
      {
        type: 'single_all_in_one',
        closest: true,
      },
      {
        headers: {
          'Authorization': `Bearer =TxcSDFCXVRGVESFSFRgrs34fsdfsd`,
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const matchesHotAfterTomorrowSlice = createSlice({
  name: 'matchesHotAfterTomorrow',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<any>) => {
    builder.addCase(fetchMatchesHotAfterTomorrow.pending, (state: MatchesAllEntities) => {
      state.loading = true;
    });
    builder.addCase(fetchMatchesHotAfterTomorrow.fulfilled, (state: MatchesAllEntities, action: any) => {
      state.loading = false;
      state.matches = action.payload;
      state.error = '';
    });
    builder.addCase(fetchMatchesHotAfterTomorrow.rejected, (state: MatchesAllEntities, action: any) => {
      state.loading = false;
      state.matches = [];
      state.error = action.error.message;
    });
  },
});

export default matchesHotAfterTomorrowSlice.reducer;
