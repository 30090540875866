import React from 'react';
import s from './empty.module.scss';


const Empty: React.FC = () => {
  return (
    <div className={s.empty}>
      No matches on this day
    </div>
  );
};

export default Empty;
