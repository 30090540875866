export const getTodayDate = () => {
  const date = new Date();

  date.setDate(date.getDate()); // Set the date to yesterday

  const day = ("0" + date.getDate()).slice(-2); // Prefix the day with a 0 if it's a single digit
  const month = ("0" + (date.getMonth() + 1)).slice(-2); // Prefix the month with a 0 if it's a single digit
  const year = date.getFullYear();

  return `${day}_${month}_${year}`; // Return the formatted date
};

export const getTomorrowDate = () => {
  const date = new Date();
  date.setDate(date.getDate() + 1); // Set the date to yesterday

  const day = ("0" + date.getDate()).slice(-2); // Prefix the day with a 0 if it's a single digit
  const month = ("0" + (date.getMonth() + 1)).slice(-2); // Prefix the month with a 0 if it's a single digit
  const year = date.getFullYear();

  return `${day}_${month}_${year}`; // Return the formatted date
};

export const getYesterdayDate = () => {
  const date = new Date();
  date.setDate(date.getDate() - 1); // Set the date to yesterday

  const day = ("0" + date.getDate()).slice(-2); // Prefix the day with a 0 if it's a single digit
  const month = ("0" + (date.getMonth() + 1)).slice(-2); // Prefix the month with a 0 if it's a single digit
  const year = date.getFullYear();

  return `${day}_${month}_${year}`; // Return the formatted date
}

export const getAfterTomorrowDate = () => {
  const date = new Date();
  date.setDate(date.getDate() + 2); // Set the date to the day after tomorrow

  const day = ("0" + date.getDate()).slice(-2); // Prefix the day with a 0 if it's a single digit
  const month = ("0" + (date.getMonth() + 1)).slice(-2); // Prefix the month with a 0 if it's a single digit
  const year = date.getFullYear();

  return `${day}_${month}_${year}`; // Return the formatted date
};

export const getLastFridayOrCurrentDate = () => {
  const today = new Date();
  const dayOfWeek = today.getDay();

  // If today is Friday, use today's date
  if (dayOfWeek === 5) {
    const day = ("0" + today.getDate()).slice(-2);
    const month = ("0" + (today.getMonth() + 1)).slice(-2);
    const year = today.getFullYear();
    return `${day}_${month}_${year}`;
  }

  // Calculate days to last Friday
  const daysToFriday = (dayOfWeek + 2) % 7;
  const lastFriday = new Date(today);
  lastFriday.setDate(today.getDate() - daysToFriday);

  // Format last Friday's date
  const day = ("0" + lastFriday.getDate()).slice(-2);
  const month = ("0" + (lastFriday.getMonth() + 1)).slice(-2);
  const year = lastFriday.getFullYear();

  return `${day}_${month}_${year}`;
};
