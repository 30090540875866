// import { FC } from "react";
// import { useLocation } from "react-router-dom";
// import s from "./subscribe-button.module.scss";

// const getSubscriptionLink = (src: string | null) => {
//   switch (src) {
//     case "stream1":
//       return "https://t.me/Expert365ai_bot?start=67cf5c5b398cf0fc790b03c4";
//     case "stream2":
//       return "https://t.me/Expert365ai_bot?start=67cf5cc6bd79f9c9a408ca57";
//     default:
//       return "https://t.me/Expert365ai_bot?start=67c4ab9d39bde08f7804462e";
//   }
// };

// const SubscribeButton: FC = () => {
//   const location = useLocation();
//   const urlParams = new URLSearchParams(location.search);
//   const src = urlParams.get("src");
//   const subscriptionLink = getSubscriptionLink(src);

//   return (
//     <a href={subscriptionLink} className={s.subscribeButton} target="_blank" rel="noopener noreferrer">
//       Subscribe Now
//     </a>
//   );
// };

// export default SubscribeButton;

import { FC } from "react";
import { useLocation } from "react-router-dom";
import s from "./subscribe-button.module.scss";
import TelegramIcon from "../../../assets/telegram-icon.png"; // Убедись, что здесь новый файл!

const getSubscriptionLink = (src: string | null) => {
  switch (src) {
    case "stream1":
      return "https://t.me/FootRoll_bot?start=67e05407d42fc434310f50b6";
    case "stream2":
      return "https://t.me/FootRoll_bot?start=67e44cf97715c212b2086464";
    default:
      return "https://t.me/FootRoll_bot?start=67b0f9cc79899d9e05068990";
  }
};

const SubscribeButton: FC = () => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const src = urlParams.get("src");
  const subscriptionLink = getSubscriptionLink(src);

  return (
    <a
      href={subscriptionLink}
      className={s.subscribeButton}
      target="_blank"
      rel="noopener noreferrer"
    >
      Subscribe Now
      <img src={TelegramIcon} alt="Telegram" className={s.iconRight} /> {/* Иконка справа */}
    </a>
  );
};

export default SubscribeButton;
