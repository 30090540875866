import React, { useEffect, useMemo, useState } from 'react';
import s from './all-events.module.scss';
import {
  setMoreCountToday,
  setMoreCountTomorrow,
  setMoreCountYesterday
} from '../../../store/slices/tabShowMoreSlice';
import MatchItem, { matchType } from '../../elements/match-item/match-item';
import Button from '../../elements/button/button';
import { useAppDispatch, useAppSelector } from '../../helper/redux-hook';
import { Match } from '../../../store/api.common';


interface AllEventsTabProps {
  items: Match[],
  title: string,
}

const AllEventsTab: React.FC<AllEventsTabProps> = (
  {
    items,
    title,
  }
) => {
  items = [...items].reverse()
  const dispatch = useAppDispatch();
  const countToday = useAppSelector(state => state.moreCount.today);
  const countYesterday = useAppSelector(state => state.moreCount.yesterday);
  const countTomorrow = useAppSelector(state => state.moreCount.tomorrow);

  const [count, setShowMore] = useState<number>(15);

  useEffect(() => {
    if (title === countToday.title) {
      setShowMore(countToday.count)
    }
    if (title === countYesterday.title) {
      setShowMore(countYesterday.count)
    }
    if (title === countTomorrow.title) {
      setShowMore(countTomorrow.count)
    }
  }, [title, countToday, countYesterday, countTomorrow])

  const croppedMatch = useMemo(() => {
    return items.slice(0, +count);
  }, [count,items]);

  const showMore = () => {
    if (title === countToday.title) {
      dispatch(setMoreCountToday(15))
    }
    if (title === countYesterday.title) {
      dispatch(setMoreCountYesterday(15))
    }
    if (title === countTomorrow.title) {
      dispatch(setMoreCountTomorrow(15))
    }
  }

  return (
    <>
      {croppedMatch.map((match: Match, index: number) => (
        <div
          key={index}
          className={s.itemWrapper}
        >
          <MatchItem
            match={match}
            matchType={matchType.all}
          />
        </div>
      ))}

      {items.length > count && (
        <div className={s.showMoreButton}>
          <Button
            text="Show more"
            modifiers={['blur']}
            clickHandler={showMore}
          />
        </div>
      )}
    </>
  );
};

export default AllEventsTab;
