// import React, { useCallback, useEffect, useMemo, useState } from 'react';
// import s from './hot-events.module.scss';
// import Title from '../../elements/title/title';
// import Tabs, { TabId } from '../../elements/tabs/tabs';
// import { useAppDispatch, useAppSelector } from '../../helper/redux-hook';
// import MatchItem, { matchType } from '../../elements/match-item/match-item';
// import { TabsMatchEntity } from '../../common';
// import MatchTextBlock from '../../elements/match-text-block/match-text-block';
// import Empty from '../../elements/empty/empty';
// import { cn } from '../../helper/classNames';
// import { Match } from '../../../store/api.common';
// import { fetchMatchesHotToday, fetchMatchesHotTomorrow, fetchMatchesHotYesterday } from '../../../store/slices/matches';


// const title = 'FREE';
// const hotEventDescription =  `
// - version, you can get access to 1 event per day—stay in the game with daily predictions! ⚽🔍

// Want unlimited access and insights for 111 football competitions? Upgrade to a 30-day subscription and never miss a game! 🚀
// `;
// const HotEvents: React.FC = () => {
//   const dispatch = useAppDispatch();

//   const matchesTodayHot: Match[] = useAppSelector(state => state.matchesHotToday.matches);
//   const matchesYesterdayHot: Match[]  = useAppSelector(state => state.matchesHotYesterday.matches);
//   const matchesTomorrowHot: Match[] = useAppSelector(state => state.matchesHotTomorrow.matches);

// /*  const [matchesTodayHotState, setMatchesTodayHotState] = useState([]);
//   const [matchesYesterdayHotState, setMatchesYesterdayHotState] = useState([]);
//   const [matchesTomorrowHotState, setMatchesTomorrowHotState] = useState([]);*/
//   const [currentTab, toggleTab] = useState<TabId>();

//   // const date = localStorage.getItem('hourWhenReset');

  // const tabs: TabsMatchEntity[] = useMemo(() => {
  //   return [
  //     {
  //       'title': 'Today',
  //       'items': matchesYesterdayHot,
  //       // 'items': matchesYesterdayHot || matchesYesterdayHotState,
  //     },
  //     {
  //       'title': 'Tomorrow',
  //       'items': matchesTodayHot,
  //       // 'items': matchesTodayHot || matchesTodayHotState,
  //     },
  //     {
  //       'title': 'After Tomorrow',
  //       'items': matchesTomorrowHot,
  //       // 'items': matchesTomorrowHot || matchesTomorrowHotState,
  //     },
  //   ];
  // }, [
  //   // matchesYesterdayHotState,
  //   matchesYesterdayHot,
  //   // matchesTodayHotState,
  //   matchesTodayHot,
  //   // matchesTomorrowHotState,
  //   matchesTomorrowHot,
  // ]);

//   const tabListIsEmpty = useMemo(() => {
//     return tabs[0].items.length === 0
//       && tabs[1].items.length === 0
//       && tabs[2].items.length === 0;
//   },[tabs]);

//   const selectedDefaultTab = useCallback(() => {
//     if (!!matchesTodayHot.length && !matchesTodayHot.some(match => match.cards)) {
//       return toggleTab(tabs[1].title);
//     }
//     if (!!matchesTomorrowHot.length && !matchesTomorrowHot.some(match => match.cards)) {
//       return toggleTab(tabs[2].title);
//     }
//     if (!!matchesYesterdayHot.length && !matchesYesterdayHot.some(match => match.cards)) {
//       return toggleTab(tabs[0].title);
//     }
//   }, [matchesYesterdayHot, matchesTodayHot, matchesTomorrowHot, toggleTab, tabs]);

//   useEffect(() => {
//     selectedDefaultTab();
//   }, [selectedDefaultTab]);

//   useEffect(() => {
//     dispatch(fetchMatchesHotYesterday());
//     dispatch(fetchMatchesHotToday());
//     dispatch(fetchMatchesHotTomorrow());
//   }, [dispatch]);

//   return (
//     <div className="container">
//       <div className={s.hotEvents}>
//         <div className={s.titleWrapper}>
//           <Title title={`🔥 ${title} events`} />
//           {!!tabs && (
//             <div className={s.tabs}>
//               <Tabs
//                 items={tabs}
//                 current={currentTab}
//                 onSelect={toggleTab}
//               />
//             </div>
//           )}
//         </div>

//         {tabs?.map(((tab: TabsMatchEntity) => currentTab === tab.title && !!tab.items.length && (
//           <div
//             key={tab.title}
//             className={cn([
//               'matches-items',
//               tab.items.length === 1 && 'matches-items--one-item',
//             ])}
//           >
//             {[...tab.items].reverse().map((match: Match, index) => (
//               <div
//                 key={index}
//                 className="matches-item"
//               >
//                 <MatchItem
//                   match={match}
//                   matchType={matchType.hot}
//                 />
//               </div>
//             ))}
//           </div>
//         )))}

//         {tabListIsEmpty && (
//           <Empty />
//         )}

//         <MatchTextBlock
//           title={title}
//           text={hotEventDescription}
//         />
//       </div>
//     </div>
//   );
// };
import React, { useEffect, useMemo, useState } from 'react';
import s from './hot-events.module.scss';
import Title from '../../elements/title/title';
import Tabs, { TabId, Category } from '../../elements/tabs/tabs';
import { useAppDispatch, useAppSelector } from '../../helper/redux-hook';
import MatchItem, { matchType } from '../../elements/match-item/match-item';
import { TabsMatchEntity } from '../../common';
import MatchTextBlock from '../../elements/match-text-block/match-text-block';
import Empty from '../../elements/empty/empty';
import { cn } from '../../helper/classNames';
import { Match } from '../../../store/api.common';
import { fetchMatchesHotToday, fetchMatchesHotYesterday, fetchMatchesHotTomorrow } from '../../../store/slices/matches';
import GetBonus from '../../modals/modal-get-bonus'; // ✅ Импорт без изменений

const title = 'TRY FREE';
const hotEventDescription = `FREE version, you can get access to only 1 event per day—stay in the game with daily predictions! ⚽🔍<br />
Want unlimited access and insights for 111 football competitions?<br />
Upgrade to a 30-day subscription and never miss a game! 🚀<br><br />`;

const HotEvents: React.FC = () => {
  const dispatch = useAppDispatch();
  const [isBonusOpen, setIsBonusOpen] = useState(false); // ✅ Управляем состоянием попапа

  const matchesTodayHot: Match[] = useAppSelector((state) => state.matchesHotToday.matches);
  const matchesYesterdayHot: Match[] = useAppSelector((state) => state.matchesHotYesterday.matches);
  const matchesTomorrowHot: Match[] = useAppSelector((state) => state.matchesHotTomorrow.matches);

  const [currentTab, toggleTab] = useState<TabId>('Today');

  const tabs: TabsMatchEntity[] = useMemo(() => {
    return [
      { title: 'Today', items: matchesTodayHot },
      { title: 'Tomorrow', items: matchesTomorrowHot },
      { title: 'Yesterday', items: matchesYesterdayHot },
    ];
  }, [matchesTodayHot, matchesTomorrowHot, matchesYesterdayHot]);

  const tabListIsEmpty = useMemo(() => {
    return tabs.every(tab => tab.items.length === 0);
  }, [tabs]);

  const category: Category = 'HOT EVENTS';

  useEffect(() => {
    dispatch(fetchMatchesHotToday());
    dispatch(fetchMatchesHotYesterday());
    dispatch(fetchMatchesHotTomorrow());
  }, [dispatch]);

  const filteredTabs = tabs.filter(tab => ['Today', 'Tomorrow', 'Yesterday'].includes(tab.title));

  return (
    <div className="container">
      <div className={s.hotEvents}>
        <div className={s.titleWrapper}>
          <Title title={`🔥 ${title}`} />
          {!!filteredTabs.length && (
            <div className={s.tabs}>
              {/* <Tabs category={category} items={filteredTabs} current={currentTab} onSelect={toggleTab} /> */}
              <Tabs category={category} items={filteredTabs} current={currentTab} onSelect={() => {}} />
            </div>
          )}
        </div>

        {filteredTabs.some(tab => tab.title === currentTab && tab.items.length > 0) ? (
          <div className={s.eventWrapper}>
            {filteredTabs
              .filter(tab => tab.title === currentTab && tab.items.length > 0)
              .map(tab => (
                <div key={tab.title} className={s.centeredItem}>
                  {[...tab.items].reverse().map((match: Match, index) => (
                    <div key={index} className={s.matchItem}>
                      <MatchItem match={match} matchType={matchType.hot} />
                    </div>
                  ))}
                </div>
              ))}
          </div>
        ) : (
          <Empty />
        )}
        <MatchTextBlock title={title} htmlContent={hotEventDescription} />
      </div>
    </div>
  );
};

export default HotEvents;
