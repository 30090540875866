// import React, { useEffect } from 'react';
// import s from './jackpot-events.module.scss';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
// import 'swiper/css';
// import 'swiper/css/pagination';
// import 'swiper/css/navigation';
// import Title from '../../elements/title/title';
// import { useAppDispatch, useAppSelector } from '../../helper/redux-hook';
// import MatchItem, { matchType } from '../../elements/match-item/match-item';
// import MatchTextBlock from '../../elements/match-text-block/match-text-block';
// import Empty from '../../elements/empty/empty';
// import { getIsMobile } from '../../common';
// import { cn } from '../../helper/classNames';
// import { fetchMatchesJackpot, matchesJackpotSlice } from '../../../store/slices/matches';


// const title = 'Last predictions';
// const JackpotEventDescription = `In this section, you can review the results of the last 20 predictions made by our bot. We always strive to provide accurate information. \n

// 🎯 Types of bets made previously: \n
// ⚽️ 1. BTTS (Both teams to score: Yes / No) \n

// ⚽️ 2. Match Winner (Home Win / Away Win) \n

// ⚽️ 3. Totals in the Game (Total Over 2.5 / Total Under 2.5)`

// const JackpotEvents: React.FC = () => {
//   const dispatch = useAppDispatch();
//   const isMobile = getIsMobile();

//   const matchesJackpot = useAppSelector(state => state.matchesJackpotSlice.matches);

//   useEffect(() => {
//     dispatch(fetchMatchesJackpot());
//   }, [dispatch]);

//   return (
//     <div className="container">
//       <div className={s.jackpotEvents}>
//         <div className={s.titleWrapper}>
//           <Title
//             title={`💰 ${title}`}
//             count={matchesJackpot.length}
//           />
//         </div>

//         {!isMobile &&(
//           <div className={s.items}>
//             <Swiper
//               modules={[Navigation, Pagination, Scrollbar, A11y]}
//               slidesPerView={3}
//               slidesPerGroup={3}
//               spaceBetween={24}
//               navigation
//               pagination={{
//                 clickable: true,
//                 type: 'custom',
//                 renderCustom: function (swiper: any, current: number, total: number) {
//                   return `<div class="${s.pagination}">${current} <span>of</span> ${total}</div>`;
//                 }
//               }}
//               scrollbar={{ draggable: true }}
//             >
//               {!!matchesJackpot.length && matchesJackpot?.map((match: any, index) => (
//                 <SwiperSlide key={index}>
//                   <div className={s.itemWrapper}>
//                     <MatchItem
//                       match={match}
//                       matchType={matchType.jackpot}
//                     />
//                   </div>
//                 </SwiperSlide>
//               ))}
//             </Swiper>
//           </div>
//         )}

//         {isMobile && (
//           <div
//             className={cn([
//               'matches-items',
//               matchesJackpot.length === 1 && 'matches-items--one-item',
//             ])}
//           >
//             {!!matchesJackpot.length && matchesJackpot?.map((match: any, index) => (
//               <div
//                 className="matches-item"
//                 key={index}
//               >
//                 <MatchItem
//                   match={match}
//                   matchType={matchType.jackpot}
//                 />
//               </div>
//             ))}
//           </div>
//         )}

//         {!matchesJackpot && (
//           <Empty />
//         )}

//         <MatchTextBlock
//           title={title}
//           text={JackpotEventDescription}
//         />
//       </div>
//     </div>
//   );
// };
// import React, { useEffect } from 'react';
// import s from './jackpot-events.module.scss';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
// import 'swiper/css';
// import 'swiper/css/pagination';
// import 'swiper/css/navigation';
// import Title from '../../elements/title/title';
// import { useAppDispatch, useAppSelector } from '../../helper/redux-hook';
// import MatchItem, { matchType } from '../../elements/match-item/match-item';
// import MatchTextBlock from '../../elements/match-text-block/match-text-block';
// import Empty from '../../elements/empty/empty';
// import { getIsMobile } from '../../common';
// import { cn } from '../../helper/classNames';
// import { fetchMatchesJackpot } from '../../../store/slices/matches';
// import SubscribeButton from '../../elements/subscribe/subscribe-button';

// // Текст и описание для кнопки
// const title = 'Last predictions';
// const JackpotEventDescription = `In this section, you can review the results of the last 20 predictions made by our bot. We always strive to provide accurate information. <br>
//   🎯 Types of bets made previously: <br>
//   ⚽️ 1. BTTS ( Both teams to score: Yes / No ) <br>
//   ⚽️ 2. Match Winner ( Home Win / Away Win ) <br>
//   ⚽️ 3. Totals in event ( Over 2.5 / Under 2.5 )<br><br />`;

// const JackpotEvents: React.FC = () => {
//   const dispatch = useAppDispatch();
//   const isMobile = getIsMobile();

//   // Получаем данные матчей из состояния
//   const matchesJackpot = useAppSelector(state => state.matchesJackpotSlice.matches);

//   useEffect(() => {
//     dispatch(fetchMatchesJackpot()); // Загружаем данные при монтировании компонента
//   }, [dispatch]);

//   return (
//     <div className="container">
//       <div className={s.jackpotEvents}>
//         <div className={s.titleWrapper}>
//           <Title title={`💰 ${title}`} count={matchesJackpot.length} />
//         </div>

//         {/* Для больших экранов */}
//         {!isMobile && (
//           <div className={s.items}>
//             <Swiper
//               modules={[Navigation, Pagination, Scrollbar, A11y]}
//               slidesPerView={3}
//               slidesPerGroup={3}
//               spaceBetween={24}
//               navigation
//               pagination={{
//                 clickable: true,
//                 type: 'custom',
//                 renderCustom: function (swiper: any, current: number, total: number) {
//                   return `<div class="${s.pagination}">${current} <span>of</span> ${total}</div>`;
//                 }
//               }}
//               scrollbar={{ draggable: true }}
//             >
//               {!!matchesJackpot.length &&
//                 matchesJackpot?.map((match: any, index: number) => (
//                   <SwiperSlide key={index}>
//                     <div className={s.itemWrapper}>
//                       <MatchItem match={match} matchType={matchType.jackpot} />
//                     </div>
//                   </SwiperSlide>
//                 ))}
//             </Swiper>
//           </div>
//         )}

//         {/* Для мобильных устройств */}
//         {isMobile && (
//           <div className={cn(['matches-items', matchesJackpot.length === 1 && 'matches-items--one-item'])}>
//             {!!matchesJackpot.length &&
//               matchesJackpot?.map((match: any, index: number) => (
//                 <div className="matches-item" key={index}>
//                   <MatchItem match={match} matchType={matchType.jackpot} />
//                 </div>
//               ))}
//           </div>  
//         )}

//         {/* Если данных нет */}
//         {!matchesJackpot.length && <Empty />}

//         {/* Используем компонент SubscribeButton */}
//         <div className={s.subscribeButtonWrapper}>
//           <SubscribeButton />
//         </div>

//         {/* Передаем HTML-контент в компонент MatchTextBlock с новыми стилями */}
//         <MatchTextBlock title={title} htmlContent={JackpotEventDescription} />
//       </div>
//     </div>
//   );
// };

// export default JackpotEvents;

import React, { useEffect } from 'react';
import s from './jackpot-events.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import Title from '../../elements/title/title';
import { useAppDispatch, useAppSelector } from '../../helper/redux-hook';
import MatchItem, { matchType } from '../../elements/match-item/match-item';
import MatchTextBlock from '../../elements/match-text-block/match-text-block';
import Empty from '../../elements/empty/empty';
import { getIsMobile } from '../../common';
import { cn } from '../../helper/classNames';
import { fetchMatchesJackpot } from '../../../store/slices/matches';
import SubscribeButton from '../../elements/subscribe/subscribe-button';

const title = 'Last predictions';
const JackpotEventDescription = `In this section, you can review the results of the last 20 predictions made by our bot. We always strive to provide accurate information. <br>
  🎯 Types of bets made previously: <br>
  ⚽️ 1. BTTS ( Both teams to score: Yes / No ) <br>
  ⚽️ 2. Match Winner ( Home Win / Away Win ) <br>
  ⚽️ 3. Totals in event ( Over 2.5 / Under 2.5 )<br><br />`;

const JackpotEvents: React.FC = () => {
  const dispatch = useAppDispatch();
  const isMobile = getIsMobile();
  const matchesJackpot = useAppSelector(state => state.matchesJackpotSlice.matches);

  useEffect(() => {
    dispatch(fetchMatchesJackpot());
  }, [dispatch]);

  return (
    <div className="container">
      <div className={s.jackpotEvents}>
        <div className={s.titleWrapper}>
          <Title title={`💰 ${title}`} count={matchesJackpot.length} />
        </div>

        {!isMobile && (
          <div className={s.items}>
            <Swiper
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              slidesPerView={3}
              slidesPerGroup={3}
              spaceBetween={24}
              navigation
              pagination={{
                clickable: true,
                type: 'custom',
                renderCustom: function (swiper: any, current: number, total: number) {
                  return `<div class="${s.pagination}">${current} <span>of</span> ${total}</div>`;
                }
              }}
              scrollbar={{ draggable: true }}
            >
              {!!matchesJackpot.length &&
                matchesJackpot?.map((match: any, index: number) => (
                  <SwiperSlide key={index}>
                    <div className={s.itemWrapper}>
                      <MatchItem match={match} matchType={matchType.jackpot} />
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        )}

        {isMobile && (
          <div className={cn(['matches-items', matchesJackpot.length === 1 && 'matches-items--one-item'])}>
            {!!matchesJackpot.length &&
              matchesJackpot?.map((match: any, index: number) => (
                <div className="matches-item" key={index}>
                  <MatchItem match={match} matchType={matchType.jackpot} />
                </div>
              ))}
          </div>  
        )}

        {!matchesJackpot.length && <Empty />}

        <div className={s.subscribeButtonWrapper}>
          <SubscribeButton />
        </div>

        <MatchTextBlock title={title} htmlContent={JackpotEventDescription} />
      </div>
    </div>
  );
};

export default JackpotEvents;
