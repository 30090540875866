// import type { FC } from 'react';
// import { useEffect, useState } from 'react';
// import s from './ball-stars.module.scss';
// import { Link } from 'react-router-dom';
// import Button from '../../elements/button/button';
// import logo from '../../../assets/logo.svg';
// import bonusIcon from '../../../assets/bonus.png';
// import { useAppDispatch } from '../../helper/redux-hook';
// import { CustomerBasicModal, customerOpenBasicModal } from '../../../store/slices/customerSlice';


// const StarsBg = require('../../../assets/ball-stars/stars.webm') as string;
// const StarsBgMp4 = require('../../../assets/ball-stars/stars.mp4') as string;
// const Ball = require('../../../assets/ball-stars/ball.webm') as string;
// const BallMp4 = require('../../../assets/ball-stars/ball.mp4') as string;
// const BallPng = require('../../../assets/ball-stars/ball.png') as string;


// const labelItems = [
//   'Enjoy all games for free',
//   'Machine Learning view',
//   'Get a bonus',
// ];

// const BallStars: FC = () => {
//   const dispatch = useAppDispatch();

//   const [currentTextIndex, setCurrentTextIndex] = useState(0);
//   const [displayedText, setDisplayedText] = useState('');

//   const [videoLoaded, setVideoLoaded] = useState(false);
//   const videoElement = document.getElementById('starsVideo') as HTMLVideoElement;
//   const handleVideoLoad = () => {
//     setVideoLoaded(true);
//   };

//   const openModalGetBonus = () => {
//     dispatch(customerOpenBasicModal(CustomerBasicModal.getBonus))
//   };

//   useEffect(() => {
//     if (videoElement) {

//       videoElement.addEventListener('canplaythrough', handleVideoLoad);

//       return () => {
//         videoElement.removeEventListener('canplaythrough', handleVideoLoad);
//       };
//     }
//   }, [videoElement]);

//   useEffect(() => {
//     const intervalId = setInterval(() => {
//       setCurrentTextIndex((prevIndex) => (prevIndex + 1) % labelItems.length);
//     }, 3000);

//     return () => clearInterval(intervalId);
//   }, []);

//   useEffect(() => {
//     let charIndex = 0;

//     const textInterval = setInterval(() => {
//       setDisplayedText(() => {
//         const currentText = labelItems[currentTextIndex];
//         if (charIndex < currentText.length) {
//           charIndex += 1;
//         } else {
//           clearInterval(textInterval);
//           setTimeout(() => {
//             charIndex = 0;
//           }, 1000); // Задержка перед сменой текста
//         }
//         return currentText.slice(0, charIndex);
//       });
//     }, 100);

//     return () => clearInterval(textInterval);
//   }, [currentTextIndex]);

//   return (
//     <div className={s.container}>
//       label
//       <video
//         className={s.starsBg}
//         autoPlay
//         loop
//         muted
//         playsInline
//       >
//         <source src={StarsBg} type="video/webm" />
//         <source src={StarsBgMp4} type="video/mp4" />
//         Your browser does not support the video tag.
//       </video>

//       <Link to="/" className={s.logo}>
//         <img src={logo} alt="logo" />
//       </Link>
//       <div className={s.content}>
//         <div className={s.labelWrapper}>
//           <div className={s.labelText}>
//             {displayedText}
//           </div>
//         </div>
//         <div className={s.ballWrapper}>
//           <img
//             src={BallPng}
//             alt="Ball"
//             className={s.ball}
//             style={{ display: videoLoaded ? 'none' : 'block' }}
//           />
//           <video
//             id="starsVideo"
//             className={s.ball}
//             autoPlay
//             loop
//             muted
//             playsInline
//             onLoadedData={handleVideoLoad}
//           >
//             <source src={Ball} type="video/webm" />
//             <source src={BallMp4} type="video/mp4" />
//             Your browser does not support the video tag.
//           </video>
//         </div>

//         <Button
//           text="Get bonus"
//           modifiers={['main']}
//           icon={bonusIcon}
//           clickHandler={openModalGetBonus}
//         />
//       </div>
//     </div>
//   );
// };

// export default BallStars;

// import type { FC } from 'react';
// import { useEffect, useState } from 'react';
// import s from './ball-stars.module.scss';
// import { Link } from 'react-router-dom';
// import logo from '../../../assets/logo.svg';
// import { useAppDispatch } from '../../helper/redux-hook';
// import { CustomerBasicModal, customerOpenBasicModal } from '../../../store/slices/customerSlice';

// const BallPng = require('../../../assets/ball-stars/ball.png') as string;

// const labelItems = [
//   'Enjoy all games for free',
//   'Machine Learning view',
//   'Get a bonus',
// ];

// const getSubscriptionLink = () => {
//   const urlParams = new URLSearchParams(window.location.search);
//   const src = urlParams.get('src');

//   switch (src) {
//     case 'stream1':
//       return 'https://t.me/Expert365ai_bot?start=67cf5c5b398cf0fc790b03c4';
//     case 'stream2':
//       return 'https://t.me/Expert365ai_bot?start=67cf5cc6bd79f9c9a408ca57';
//     default:
//       return 'https://t.me/Expert365ai_bot?start=67c4ab9d39bde08f7804462e';
//   }
// };

// const BallStars: FC = () => {
//   const dispatch = useAppDispatch();
//   const [currentTextIndex, setCurrentTextIndex] = useState(0);
//   const [displayedText, setDisplayedText] = useState('');
//   const subscriptionLink = getSubscriptionLink();

//   useEffect(() => {
//     const intervalId = setInterval(() => {
//       setCurrentTextIndex((prevIndex) => (prevIndex + 1) % labelItems.length);
//     }, 3000);

//     return () => clearInterval(intervalId);
//   }, []);

//   useEffect(() => {
//     let charIndex = 0;
//     const textInterval = setInterval(() => {
//       setDisplayedText(() => {
//         const currentText = labelItems[currentTextIndex];
//         if (charIndex < currentText.length) {
//           charIndex += 1;
//         } else {
//           clearInterval(textInterval);
//           setTimeout(() => {
//             charIndex = 0;
//           }, 1000);
//         }
//         return currentText.slice(0, charIndex);
//       });
//     }, 100);

//     return () => clearInterval(textInterval);
//   }, [currentTextIndex]);

//   return (
//     <div className={s.container}>
//       <Link to="/" className={s.logo}>
//         <img src={logo} alt="logo" />
//       </Link>
//       <div className={s.content}>
//         <div className={s.labelWrapper}>
//           <div className={s.labelText}>{displayedText}</div>
//         </div>
//         <div className={s.ballWrapper}>
//           <img src={BallPng} alt="Ball" className={s.ball} />
//         </div>
//         <a href={subscriptionLink} className={s.subscribeButton} target="_blank" rel="noopener noreferrer">
//           Subscribe Now
//         </a>
//       </div>
//     </div>
//   );
// };

// export default BallStars;

// import type { FC } from 'react';
// import s from './ball-stars.module.scss';
// import { Link } from 'react-router-dom';
// import logo from '../../../assets/logo.svg';

// const BallPng = require('../../../assets/ball-stars/ball.png') as string;

// const labelItems = [
//   'Unlock Winning Insights!'
// ];

// const getSubscriptionLink = () => {
//   const urlParams = new URLSearchParams(window.location.search);
//   const src = urlParams.get('src');

//   switch (src) {
//     case 'stream1':
//       return 'https://t.me/Expert365ai_bot?start=67cf5c5b398cf0fc790b03c4';
//     case 'stream2':
//       return 'https://t.me/Expert365ai_bot?start=67cf5cc6bd79f9c9a408ca57';
//     default:
//       return 'https://t.me/Expert365ai_bot?start=67c4ab9d39bde08f7804462e';
//   }
// };

// const BallStars: FC = () => {
//   const subscriptionLink = getSubscriptionLink();
  
//   return (
//     <div className={s.container}>
//       <Link to="/" className={s.logo}>
//         <img src={logo} alt="logo" />
//       </Link>
//       <div className={s.content}>
//         <div className={s.labelWrapper}>
//           <div className={s.labelText}>{labelItems[0]}</div>
//         </div>
//         <div className={s.ballWrapper}>
//           <img src={BallPng} alt="Ball" className={s.ball} />
//         </div>
//         <a href={subscriptionLink} className={s.subscribeButton} target="_blank" rel="noopener noreferrer">
//           Subscribe Now
//         </a>
//       </div>
//     </div>
//   );
// };

// export default BallStars;

// import type { FC } from 'react';
// import { Link } from 'react-router-dom';
// import s from './ball-stars.module.scss';
// import logo from '../../../assets/logo.svg';
// import SubscribeButton from '../../elements/subscribe/subscribe-button';

// const BallPng = require('../../../assets/ball-stars/ball.png') as string;

// const labelItems = [
//   'AI-Powered Wins: 84%+ 🎯'
// ];

// const BallStars: FC = () => {
//   return (
//     <div className={s.container}>
//       <Link to="/" className={s.logo}>
//         <img src={logo} alt="logo" />
//       </Link>
//       <div className={s.content}>
//         <div className={s.labelWrapper}>
//           <div className={s.labelText}>{labelItems[0]}</div>
//         </div>
//         <div className={s.ballWrapper}>
//           <img src={BallPng} alt="Ball" className={s.ball} />
//         </div>
//         <SubscribeButton />
//       </div>
//     </div>
//   );
// };

// export default BallStars;
// import type { FC } from 'react';
// import s from './ball-stars.module.scss';
// import SubscribeButton from '../../elements/subscribe/subscribe-button';

// const FansImage = require('../../../assets/fans-celebrating.png') as string;

// const labelText = 'AI-Powered Wins: 84%+ 🎯';

// const BallStars: FC = () => {
//   return (
//     <div className={s.container}>
//       {/* Фоновое изображение */}
//       <div className={s.imageWrapper}>
//         <img src={FansImage} alt="Happy Fans" className={s.image} />
//       </div>

//       {/* Контент: заголовок и кнопка */}
//       <div className={s.content}>
//         <div className={s.labelWrapper}>{labelText}</div>
//         <SubscribeButton />
//       </div>
//     </div>
//   );
// };

// export default BallStars;


import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import s from './ball-stars.module.scss';
import SubscribeButton from '../../elements/subscribe/subscribe-button';
// import logo from '../../../assets/logo.svg';

const StarsBg = require('../../../assets/ball-stars/stars.webm') as string;
const StarsBgMp4 = require('../../../assets/ball-stars/stars.mp4') as string;
const Ball = require('../../../assets/ball-stars/ball.webm') as string;
const BallMp4 = require('../../../assets/ball-stars/ball.mp4') as string;
const BallPng = require('../../../assets/ball-stars/ball.png') as string;

const labelItems = [
  'Welcome to FOOTROLL 🚀',
  'Powered by AI Bot 🏆',
  'Grab Your $5 Bonus — TRY FREE 🔥',
  'Daily Free Picks — All Events ⚽️',
];

const BallStars: FC = () => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [displayedText, setDisplayedText] = useState('');
  const [videoLoaded, setVideoLoaded] = useState(false);

  useEffect(() => {
    const videoElement = document.getElementById('starsVideo') as HTMLVideoElement;
    if (videoElement) {
      videoElement.addEventListener('canplaythrough', () => setVideoLoaded(true));
      return () => {
        videoElement.removeEventListener('canplaythrough', () => setVideoLoaded(true));
      };
    }
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % labelItems.length);
    }, 3000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    let charIndex = 0;
    const textInterval = setInterval(() => {
      setDisplayedText(() => {
        const currentText = labelItems[currentTextIndex];
        if (charIndex < currentText.length) {
          charIndex += 1;
        } else {
          clearInterval(textInterval);
          setTimeout(() => {
            charIndex = 0;
          }, 1000);
        }
        return currentText.slice(0, charIndex);
      });
    }, 50);
    return () => clearInterval(textInterval);
  }, [currentTextIndex]);

  return (
    <div className={s.container}>
      <video className={s.starsBg} autoPlay loop muted playsInline>
        <source src={StarsBg} type="video/webm" />
        <source src={StarsBgMp4} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* <Link to="/" className={s.logo}>
        <img src={logo} alt="logo" />
      </Link> */}

      <div className={s.content}>
        <div className={s.labelWrapper}>
          <div className={s.labelText}>{displayedText}</div>
        </div>

        <div className={s.ballWrapper}>
          <img
            src={BallPng}
            alt="Ball"
            className={s.ball}
            style={{ display: videoLoaded ? 'none' : 'block' }}
          />
          <video
            id="starsVideo"
            className={s.ball}
            autoPlay
            loop
            muted
            playsInline
            onLoadedData={() => setVideoLoaded(true)}
          >
            <source src={Ball} type="video/webm" />
            <source src={BallMp4} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>

        <SubscribeButton />
      </div>
    </div>
  );
};

export default BallStars;