import type { FC } from 'react';
import s from './status.module.scss';
import { cn } from '../../helper/classNames';
import { MatchStatusType } from '../../../store/api.common';


export const statusTranslationsMap = {
  [MatchStatusType.notStarted]: 'Upcoming',
  [MatchStatusType.finished]: 'Finished',
  [MatchStatusType.live]: 'Live',
}

export const statusClassMap = {
  [MatchStatusType.notStarted]: s.notStarted,
  [MatchStatusType.finished]: s.finished,
  [MatchStatusType.live]: s.live,
}

interface StatusProps {
  status: MatchStatusType,
}

const Status: FC<StatusProps> = (
  {
    status,
  }
) => {
  return (
    <div
      className={cn([
        s.status,
        statusClassMap[status],
      ])}
    >
      {statusTranslationsMap[status]}
    </div>
  );
};

export default Status;
