// import React, { useEffect } from 'react';
// import s from './jackpot-events.module.scss';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
// import 'swiper/css';
// import 'swiper/css/pagination';
// import 'swiper/css/navigation';
// import Title from '../../elements/title/title';
// import { useAppDispatch, useAppSelector } from '../../helper/redux-hook';
// import MatchItem, { matchType } from '../../elements/match-item/match-item';
// import MatchTextBlock from '../../elements/match-text-block/match-text-block';
// import Empty from '../../elements/empty/empty';
// import { getIsMobile } from '../../common';
// import { cn } from '../../helper/classNames';
// import { fetchMatchesJackpot, matchesJackpotSlice } from '../../../store/slices/matches';


// const title = 'Last predictions';
// const JackpotEventDescription = `In this section, you can review the results of the last 20 predictions made by our bot. We always strive to provide accurate information. \n

// 🎯 Types of bets made previously: \n
// ⚽️ 1. BTTS (Both teams to score: Yes / No) \n

// ⚽️ 2. Match Winner (Home Win / Away Win) \n

// ⚽️ 3. Totals in the Game (Total Over 2.5 / Total Under 2.5)`

// const JackpotEvents: React.FC = () => {
//   const dispatch = useAppDispatch();
//   const isMobile = getIsMobile();

//   const matchesJackpot = useAppSelector(state => state.matchesJackpotSlice.matches);

//   useEffect(() => {
//     dispatch(fetchMatchesJackpot());
//   }, [dispatch]);

//   return (
//     <div className="container">
//       <div className={s.jackpotEvents}>
//         <div className={s.titleWrapper}>
//           <Title
//             title={`💰 ${title}`}
//             count={matchesJackpot.length}
//           />
//         </div>

//         {!isMobile &&(
//           <div className={s.items}>
//             <Swiper
//               modules={[Navigation, Pagination, Scrollbar, A11y]}
//               slidesPerView={3}
//               slidesPerGroup={3}
//               spaceBetween={24}
//               navigation
//               pagination={{
//                 clickable: true,
//                 type: 'custom',
//                 renderCustom: function (swiper: any, current: number, total: number) {
//                   return `<div class="${s.pagination}">${current} <span>of</span> ${total}</div>`;
//                 }
//               }}
//               scrollbar={{ draggable: true }}
//             >
//               {!!matchesJackpot.length && matchesJackpot?.map((match: any, index) => (
//                 <SwiperSlide key={index}>
//                   <div className={s.itemWrapper}>
//                     <MatchItem
//                       match={match}
//                       matchType={matchType.jackpot}
//                     />
//                   </div>
//                 </SwiperSlide>
//               ))}
//             </Swiper>
//           </div>
//         )}

//         {isMobile && (
//           <div
//             className={cn([
//               'matches-items',
//               matchesJackpot.length === 1 && 'matches-items--one-item',
//             ])}
//           >
//             {!!matchesJackpot.length && matchesJackpot?.map((match: any, index) => (
//               <div
//                 className="matches-item"
//                 key={index}
//               >
//                 <MatchItem
//                   match={match}
//                   matchType={matchType.jackpot}
//                 />
//               </div>
//             ))}
//           </div>
//         )}

//         {!matchesJackpot && (
//           <Empty />
//         )}

//         <MatchTextBlock
//           title={title}
//           text={JackpotEventDescription}
//         />
//       </div>
//     </div>
//   );
// };
import React, { useEffect } from 'react';
import s from './info-about.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import Title from '../../elements/title/title';
import { useAppDispatch } from '../../helper/redux-hook';
import MatchTextBlock from '../../elements/match-text-block/match-text-block';
import Empty from '../../elements/empty/empty';
import { getIsMobile } from '../../common';
import { cn } from '../../helper/classNames';
import { fetchMatchesJackpot } from '../../../store/slices/matches';

// 🔹 Импортируем картинки из папки `src/assets`
import image1 from '../../../assets/info_x.png';
import image2 from '../../../assets/info_telegram.png';
import image3 from '../../../assets/info_gitbook.png';
import image4 from '../../../assets/info_github.png';

const title = 'More info';
const JackpotEventDescription = `Here you can find more information about our project and enjoy our social's 😎<br><br>`;

const imageLinks = [
  { src: image1, url: 'https://x.com/footrollbot?s=11', buttonText: 'Enjoy X' },
  { src: image2, url: 'https://t.me/FootRoll_bot?start=67b0f9cc79899d9e05068990', buttonText: 'Enjoy Telegram' },
  { src: image3, url: 'https://total93.gitbook.io/foot-roll-ai', buttonText: 'Open GitBook' },
  { src: image4, url: 'https://github.com/TotalSport/ML_FootRoll', buttonText: 'Open GitHub' }
];

const JackpotEvents: React.FC = () => {
  const dispatch = useAppDispatch();
  const isMobile = getIsMobile();

  useEffect(() => {
    dispatch(fetchMatchesJackpot()); // Загружаем данные при монтировании компонента
  }, [dispatch]);

  return (
    <div className="container">
      <div className={s.jackpotEvents}>
        <div className={s.titleWrapper}>
          <Title title={`🤩 ${title}`} count={imageLinks.length} />
        </div>

        {!isMobile && (
          <div className={s.items}>
            <Swiper
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              slidesPerView={3}
              slidesPerGroup={3}
              spaceBetween={24}
              navigation
              pagination={{
                clickable: true,
                type: 'custom',
                renderCustom: (swiper: any, current: number, total: number) => (
                  `<div class="${s.pagination}">${current} <span>of</span> ${total}</div>`
                )
              }}
              scrollbar={{ draggable: true }}
            >
              {imageLinks.map((item, index) => (
                <SwiperSlide key={index}>
                  <div className={s.itemWrapper}>
                    <a href={item.url} target="_blank" rel="noopener noreferrer">
                      <img src={item.src} alt={`Slide ${index + 1}`} className={s.image} />
                    </a>
                    <a href={item.url} target="_blank" rel="noopener noreferrer" className={s.button}>
                      {item.buttonText}
                    </a>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        )}

        {isMobile && (
          <div className={cn(['matches-items', imageLinks.length === 1 && 'matches-items--one-item'])}>
            {imageLinks.map((item, index) => (
              <div className="matches-item" key={index}>
                <a href={item.url} target="_blank" rel="noopener noreferrer">
                  <img src={item.src} alt={`Slide ${index + 1}`} className={s.image} />
                </a>
                <a href={item.url} target="_blank" rel="noopener noreferrer" className={s.button}>
                  {item.buttonText}
                </a>
              </div>
            ))}
          </div>
        )}

        {!imageLinks.length && <Empty />}

        <MatchTextBlock title={title} htmlContent={JackpotEventDescription} />
      </div>
    </div>
  );
};

export default JackpotEvents;
